<template>
    <div>
        <slot name="activator" v-bind:on="scope" />
        
        <confirmation-dialog v-model="showDialog" :title="title" :confirm="$t('DELETE_CONFIRMATION_DIALOG_CONFIRM')" @confirm="confirm" @cancel="cancel" :disabled="disabled" id="DeleteConfirmationDialog">
            <v-form ref="form" :lazy-validation="true">
                <slot>{{$t('DELETE_CONFIRMATION_DIALOG_MESSAGE')}}</slot>
                <v-checkbox v-if="checkbox" v-model="deletionConfirmed" :label="checkbox === true ? $t('DELETE_CONFIRMATION_DIALOG_CHECKBOX_MESSAGE') : checkbox" :dark="isDark" :light="isLight" color="unset" :rules="deletionConfirmedRule" :disabled="disabled" class="deleteConfirmationDialogCheckbox" id="deleteConfirmationDialogCheckbox"/>
            </v-form>
        </confirmation-dialog>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { useThemeStore } from '@/stores/themeStore';

@Component
export default class DeleteConfirmationDialog extends Vue 
{  
    @Prop(Boolean) value:boolean;
    @Prop(String) title:string;
    @Prop(Boolean) disabled:boolean;
    @Prop() checkbox:string|boolean;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    // END TODO

    showDialog:boolean = false;
    deletionConfirmed:boolean = false;

    deletionConfirmedRule = [v => v === true];

    scope = {
        click: this.load
    }

    @Watch('value')
    onValueChanged(value:boolean)
    {
        if(value) this.load();
    }

    mounted() 
    {
        if(this.value) this.load();
    }

    async load() {
        await (<any>this.$refs.form)?.reset();

        this.showDialog = true;
        this.deletionConfirmed = false;
    }
    
    async confirm()
    {
        if((<any>this.$refs.form).validate() === false) return;

        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>