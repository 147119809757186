<template>
    <v-row wrap>
        <template v-if="flags.length === 0" >
            <v-col v-for="{text, onClick} in noFlagButtons" :key="text" cols="12" class="py-0 mb-0 mt-1 addFlag">
                <v-btn text class="text-none" @click="onClick" :disabled="isLoading">
                    <v-icon class="mr-2">add</v-icon> {{ text }}
                </v-btn>
            </v-col>
        </template>

        <multiple-choice-flags v-else-if="isMultipleChoiceChallenge"  :flags="flags" @update:flags="$emit('update:flags', $event)" :choice="choice" @update:choice="$emit('update:choice', $event)" @error="$emit('update:error', $event)"/>

        <text-flags v-else :flags="flags" @error="$emit('update:error', $event)" />

        <v-col cols="12" v-if="hasError" class="py-0 mb-0 mt-1 ml-3">
            <span class="caption error--text">{{errors[0]}}</span>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Flag, IFlag, FlagType } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Prop, Watch } from 'vue-property-decorator';
import { Choice, ChoiceValue, IChoice } from '@cyber-range/cyber-range-api-ctf-library-client';
import { v4 as uuid } from 'uuid';
import Validatable from '@/validations/Validatable.default.vue';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class Flags extends mixins(Validatable) 
{
    @Prop(Array) flags:IFlag[];
    @Prop(Object) choice:IChoice;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO
    
    //Begin Vuetify Validatable
    @Watch('flags', { immediate: true })
    async onFlagsChanged(v)
    {
        this.toValidateValue = this.flags;
    }
    //End Vuetify Validatable

    get isMultipleChoiceChallenge(): boolean
    {
        return this.flags?.[0]?.type === FlagType.MultipleChoice;
    }

    noFlagButtons = [
        {
            text: `${this.$t('FLAG_TEXT_FLAG')}: ${this.$t('FLAGS_ADD_FLAG')}`,
            onClick: this.setTextChallenge
        },
        {
            text: `${this.$t('FLAG_MULTIPLE_CHOICE_FLAG')}: ${this.$t('FLAGS_ADD_CHOICE')}`,
            onClick: this.setMultipleChoiceChallenge
        }
    ];

    setTextChallenge()
    {
        const defaultFlag = new Flag({ type: FlagType.String, value: '' });
        this.$emit('update:flags', [defaultFlag]);
    }

    setMultipleChoiceChallenge()
    {
        const defaultFlag = new Flag({ type: FlagType.MultipleChoice, value: [] });
        const defaultChoice = new Choice({ required: 0, values: [new ChoiceValue({ id: uuid(), text: '' }), new ChoiceValue({ id: uuid(), text: '' })] });

        this.$emit('update:flags', [defaultFlag]);
        this.$emit('update:choice', defaultChoice);
    }
}
</script>
