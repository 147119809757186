import Config from '@/config';
import { AuthenticationApiClient, IToken } from '@cyber-range/cyber-range-api-authentication-client';
import * as JsonWebToken from 'jsonwebtoken';
import moment from 'moment';
import { TrackJS } from 'trackjs';
import Route from '@/interfaces/route';
import VueRouter from 'vue-router';
import gravatar from 'gravatar';
import { useNotificationStore } from '@stores/notificationStore';
import { useApiClientStore } from './apiClientStore';
import { useEntitlementStore } from '@stores/entitlementStore';
import { defineStore } from 'pinia';
import { usePlayerStore } from './playerStore';

export const useAuthenticationStore = defineStore('authenticationStore', {
    state: () =>
    ({
		token: '',
		originalToken: <unknown> undefined as string,
		tokenExpirationTimeInMs: 0,
		identityId: '',
		identityName: '',
		identityEmail: '',
		identityAvatar: '',
		tokenTimer: <unknown> undefined as NodeJS.Timeout,
		logoutListener: <unknown> undefined as string,
		identityProvider: '',
		_isLogin: false,
    }),
    getters:
    {
		isImpersonating(state): boolean 
		{
			return state.originalToken !== undefined;
		},
		isLogin(state): boolean 
		{
			return state._isLogin || (state.token ? new Date().getTime() < state.tokenExpirationTimeInMs : false);
		},
		getIdentityAvatar(state): string 
		{
			return state.identityAvatar ? state.identityAvatar : gravatar.url(state.identityEmail, {d:'retro',s:'32'});;
		},
		isTokenExpired(state): boolean 
		{
			return (state.tokenExpirationTimeInMs / 1000) - moment().unix() <= 10 /* (10 seconds)*/
		}
    },
    actions:
    {
		setToken(token: IToken)
		{
			const tokenString = token ? token.value : undefined;

			this.token = tokenString;

			if (tokenString) {
				localStorage.setItem('token', tokenString);

				const jwt = JsonWebToken.decode(tokenString);
				this.tokenExpirationTimeInMs = (jwt['exp'] - 30) * 1000; // 30 seconds

				this.identityId = jwt['sub'];
				this.identityName = jwt['displayName'];
				this.identityEmail = jwt['email'];
				this.identityAvatar = jwt['picture'];
				this.identityProvider = jwt['provider'];
				localStorage.setItem('identityProvider', this.identityProvider);

				TrackJS.configure({
					userId: this.identityId
				});

				TrackJS.addMetadata('User Name', this.identityName);
			}
			else
			{
				localStorage.removeItem('token');
				this.tokenExpirationTimeInMs = 0;
				this.identityName = '';
				this.identityEmail = '';
				this.identityAvatar = '';

				TrackJS.configure({
					userId: ''
				});
				TrackJS.removeMetadata('User Name');
			}
		},
		setOriginalToken(originalToken: string): void 
		{
			this.originalToken = originalToken;

			if (originalToken === undefined) 
			{
				localStorage.removeItem('originalToken');
			} 
			else 
			{
				localStorage.setItem('originalToken', originalToken);
			}
		},
		clearTokenTimer() 
		{
			clearTimeout(this.tokenTimer);
		},
		resetIdentityProvider(value: string) 
		{
			this.identityProvider = value;
			localStorage.removeItem('identityProvider');
		},
		async login(request: { token: string; provider: string }): Promise<boolean> 
		{
			const isRefreshing = this.isLogin;
			const client = new AuthenticationApiClient(Config.AUTHENTICATION_API_BASE_URL);
			let token: IToken;

			try {
				token = await client.authenticate(request.provider, request.token);

				let originalToken = localStorage.getItem('originalToken');
				if(originalToken){
					this.setOriginalToken(originalToken);
				}

			} catch {
				this.clearTokenTimer();
				this.setToken(undefined);
				return false;
			}

			this.setToken(token);

			// Token renewal
			const timeToRefreshInSeconds =
				this.tokenExpirationTimeInMs / 1000 - moment().unix() - 60 /* 1 minute*/;

			if (timeToRefreshInSeconds > 60) {
				/* 1 minute */
				// Token will be valid for more than 1 minute. Good.
				const timer = setTimeout(() => {
					this.login({ token: this.token, provider: 'vcr' });
				}, timeToRefreshInSeconds * 1000);

				this.tokenTimer = timer;

				if (isRefreshing)
				{
					await usePlayerStore().renewSession();
				}
				else
				{
					await usePlayerStore().startSession();
				}
				return true;
			} else {
				// Could not productively renew the token further.
				this.clearTokenTimer();
				this.logout({ reload: true });
				return false;
			}
		},
		async tryLogin(): Promise<boolean> 
		{
			if (this.isLogin)
			{
				return true;
			}

			const token = localStorage.getItem('token');
			if (token) {
				return this.login({ token, provider: 'vcr' });
			} else {
				return false;
			}
		},
		async logout(payload?: {redirect:boolean, reload:boolean, router:VueRouter}) 
		{
			await usePlayerStore().terminateSession();
			this.clearTokenTimer();
			this.setOriginalToken(undefined);
			this.setToken(undefined);
			useNotificationStore().unsubscribe();
			if (this.logoutListener) {
				this.logoutListener();
			}

			if(payload?.redirect)
			{
				// Import 'router' here to avoid loading the router before themed page components are loaded in index.ts.
				let router = payload.router || require('@/router').default;

				try
				{
					await router.replace(Route.Landing);
				}
				catch{};
			}

			if(payload?.reload)
			{
				location.reload();
			}
		},
		async impersonate(impersonation: {userId: string, path: string}): Promise<boolean> 
		{
			const client = new AuthenticationApiClient(Config.AUTHENTICATION_API_BASE_URL);
			let token: IToken;
			const originalToken = this.token;
			
			if(impersonation.path)
			{
				localStorage.setItem('impersonationPath', impersonation.path);
			}
			try 
			{
				token = await client.authenticate('vcr', originalToken, impersonation.userId);
			} 
			catch 
			{
				this.clearTokenTimer();
				this.setToken(undefined);
				return false;
			}

			this.setOriginalToken(originalToken);
			this.setToken(token);

			await useEntitlementStore().fetchClaims();

			return true;
		},
		async exitImpersonation(): Promise<string> 
		{
			const originalToken = this.originalToken;
			this.setOriginalToken(undefined);
			this.setToken({ value: originalToken, type: 'Bearer' });
			await useEntitlementStore().fetchClaims();
			return localStorage.getItem('impersonationPath') || '';
		},
		async getSsoToken(): Promise<string> 
		{
			return await useApiClientStore().authenticationApiClient.sso();
		}
    }
});