<template>
    <v-row wrap v-if="statistics" class="ml-4 caption solvedBy">
        <v-col cols="12" v-if="statistics.successfulAttempts === 0">
            {{ $t('CHALLENGE_DIALOG_SOLVED_BY_NONE') }}
        </v-col>
        <v-col cols="12" v-if="statistics.successfulAttempts === 1">
            {{ $t('CHALLENGE_DIALOG_SOLVED_BY_SINGULAR') }}
        </v-col>
        <v-col cols="12" v-if="statistics.successfulAttempts > 1">
            {{ $t('CHALLENGE_DIALOG_SOLVED_BY_PLURAL', {number: statistics.successfulAttempts}) }}
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {  IChallengeStatistics } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Prop } from 'vue-property-decorator';

@Component
export default class SolvedBy extends Vue 
{  
    @Prop(Object) statistics:IChallengeStatistics;
}
</script>