import IAlert from '@interfaces/iAlert';
import { IPlayer } from '@cyber-range/cyber-range-api-ctf-player-client';

export default class JoiningRequestAlert implements IAlert
{
    message: string;
    referenceId: string;
    player:IPlayer;

    constructor(joiningPlayer:IPlayer)
    {
        this.player = joiningPlayer;
        this.referenceId = this.player.id;
        this.message = `${this.player.name} is requesting to join your team.`;
    }
}