<template>
    <v-row wrap>
        <v-col cols="12" class="pa-0 mb-0 mt-1 flag" v-for="choiceValue in choice.values" :key="choiceValue.id">
            <v-row wrap class="pa-0 ma-0">
                <v-col cols="12" sm="5" md="3" class="pt-0 pb-0 mb-0 mt-0" >
                    <v-checkbox color="blue" multiple :input-value="correctChoiceIds" :value="choiceValue.id" @change="onChoiceCheckboxChange" label="correct" :disabled="isLoading" :error="!!error" hide-details  @update:error="onError" />
                </v-col>
                <v-col cols="12" sm="7" md="9" class="pt-0 pb-0 mb-0 mt-0" >
                    <v-text-field v-model="choiceValue.text" :label="$t('FLAGS_VALUE')" append-outer-icon="delete" @click:append-outer="onDeleteClicked(choiceValue.id)" @update:error="onError" :rules="choiceValueTextRules" :counter="maxChoiceValueTextLength" :disabled="isLoading" class="flagValue" />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0 mb-0 mt-1 addFlag">
            <v-tooltip v-if="choice.values.length > maxChoiceCount" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn class="text-none" text disabled v-on="on">
                        <v-icon class="mr-2">add</v-icon> 
                        {{ $t('FLAGS_ADD_CHOICE' )}}
                    </v-btn>
                </template>
                <span> {{ $t('FLAGS_MAX_CHOICES_TOOLTIP', { count: maxChoiceCount }) }} </span>
            </v-tooltip>
            <v-btn v-else class="text-none addChoice" @click="onAddClicked" text :disabled="isLoading">
                <v-icon class="mr-2">add</v-icon> 
                {{ $t('FLAGS_ADD_CHOICE' )}}
            </v-btn>
        </v-col>
        <v-col cols="12" v-if="error" class="pt-0 pb-0 mb-0 mt-1">
            <span class="caption error--text">{{ error }}</span>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Flag, IFlag, FlagType } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Inject, Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { v4 as uuid } from 'uuid';
import { Choice, ChoiceValue, IChoice } from '@cyber-range/cyber-range-api-ctf-library-client';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class MultipleChoiceFlags extends Vue
{
    @Prop({ type: Object, default: () => new Choice({ required: 0, values: [new ChoiceValue({ id: uuid(), text: '' })] }) }) readonly choice: Readonly<IChoice>;
    @Prop({ type: Array, default: () => [new Flag({ value: [], type: FlagType.MultipleChoice })]}) readonly flags: Readonly<IFlag>[];

    minChoiceCount: number = 2;
    maxChoiceCount: number = 25;
    maxChoiceValueTextLength:number = 1024;
    choiceValueTextRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxChoiceValueTextLength)];

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO
    
    get correctChoiceIds(): string[]
    {
        const flagValue = this.flags?.[0]?.value || []
        const correctChoiceIds = Array.isArray(flagValue) ? flagValue : [flagValue];
        return correctChoiceIds.filter(id => this.choice.values.some(choiceValue => choiceValue.id === id));
    }

    onAddClicked()
    {
        this.$emit('update:choice', new Choice({
            required: this.correctChoiceIds.length,
            values: [...this.choice.values, new ChoiceValue({ id: uuid(), text: '' })]
        }));
    }

    onDeleteClicked(id:string)
    {
        const correctValues = new Set(this.correctChoiceIds);
        correctValues.delete(id);

        const values = this.choice.values.filter(choice => choice.id !== id);

        if (values.length === 0)
        {
            this.$emit('update:flags', []);
            this.$emit('update:choice', undefined);
        }
        else
        {
            this.$emit('update:flags', [new Flag({ type: FlagType.MultipleChoice, value: [...correctValues] })]);
            this.$emit('update:choice', new Choice({ required: correctValues.size, values }));
        }
    }

    onChoiceCheckboxChange(values: string[])
    {
        this.$emit('update:flags', [new Flag({ type: FlagType.MultipleChoice, value: values })]);
        this.$emit('update:choice', new Choice({ required: values.length, values: this.choice.values }));
    }

    onError(error:any)
    {
        this.$emit('update:error', error)
    }

    // Begin Vuetify Validation
    @Inject('form') form;

    errors = {
        minChoiceLength: '',
        minCorrectChoiceLength: ''
    };
    get error()
    {
        return Object.values(this.errors).filter(error => !!error)[0];
    }

    get hasError(): boolean
    {
        return !!this.error;
    }

    created()
    {
        this.form && this.form.register(this);
    }
    beforeDestroy()
    {
        this.form && this.form.unregister(this);
    }

    @Watch('choice.values.length')
    validateChoiceValues()
    {
        this.errors.minChoiceLength = (this.choice.values.length < this.minChoiceCount)
            ? this.$t('FLAGS_MIN_CHOICES_ERROR', { count: this.minChoiceCount }).toString()
            : '';
    }

    @Watch('correctChoiceIds')
    validateCorrectChoicesLength()
    {
        this.errors.minCorrectChoiceLength = (this.correctChoiceIds.length < 1)
            ? this.$t('FLAGS_CHOICE_REQUIRED_ERROR').toString()
            : '';
    }

    validate()
    {
        this.validateChoiceValues();
        this.validateCorrectChoicesLength();
        return !this.hasError;
    }

    reset()
    {
        this.errors = {
            minChoiceLength: '',
            minCorrectChoiceLength: ''
        };
    }
    // End Vuetify Validation
}
</script>
