<template>
    <v-card outlined :dark="isDark" :light="isLight" class="elevation-0 activityWindow window alphaBackground">
        <v-row v-if="!isReady" class="activityTableLoading">
            <v-col cols="12" align="center" class="mt-10 pt-10">
                <loading />
            </v-col>
        </v-row>

        <v-row v-else-if="activities.length === 0">
            <v-col cols="12" align="center" class="mt-10 pt-10">
                <p class="activityTableEmpty">{{$t('ACTIVITY_TABLE_EMPTY')}}</p>
            </v-col>
        </v-row>
            
        <v-simple-table v-else :dark="isDark" :light="isLight" height="191" class="activityTable">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left activityTableTimeColumn">{{$t('ACTIVITY_TABLE_TIME')}}</th>
                    <th class="text-left activityTableMessageColumn">{{$t('ACTIVITY_TABLE_MESSAGE')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="activity in activities" :key="activity.id">
                    <td class="text-left activityTableTimeColumn">{{ activity.timestamp | humanizedShort }}</td>
                    <td class="text-left activityTableMessageColumn">{{ activity.message }}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import Window from './Window.default.vue';
import IActivity from '@/interfaces/iActivity';
import { useActivityStore } from '@/stores/activityStore';
import { useThemeStore } from '@/stores/themeStore';
import { Watch } from 'vue-property-decorator';
import { useTeamStore } from '@/stores/teamStore';
import { useChallengeStore } from '@/stores/challengeStore';
import { useSubmissionStore } from '@/stores/submissionStore';

@Component
export default class ActivityWindow extends mixins(Window) 
{   
    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isLight;
    }
    // END TODO

    get activities(): IActivity[] { return useActivityStore().getActivities }
    get isReady()
    {
        return useSubmissionStore().isSubmissionFetched && useChallengeStore().isChallengesFetched && useTeamStore().isTeamsFetched;
    }

    @Watch('isReady', { immediate: true })
    onIsReadyChanged()
    {
        if (this.isReady)
        {
            useSubmissionStore().startCapturingActivities();
        }
    }
}
</script>
<style scoped>
.theme--dark.v-data-table thead th
{
    background-color: initial;
}
.activityTable
{
    background-color: unset;
}
</style>