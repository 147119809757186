//From: https://github.com/darrenfang/vuetify-datetime-picker/blob/master/src/components/DatetimePicker.vue
//Doc: https://github.com/darrenfang/vuetify-datetime-picker
//Copied here with modifications:
// - Added "dark" prop
// - Changed CANCEL/CONFIRM buttons look-and-feel
// - Added "cancel" button and changed "cancel" logic
// - Moved "clear" button to the left bottom and changed "clear" logic
// - Removed elevation from v-card and pickers
// - Changed from date-fns to momentjs
// - Allowed string to datetime as input

<template>
  <v-dialog v-model="display" :width="dialogWidth" :dark="dark" class='dateTimePicker'>
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="formattedDatetime"
        v-on="on"
        readonly
        class='dateTimeText'
      >
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear color="primary" indeterminate absolute height="2"></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card :dark="dark" class='dateTimeDialog'>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar" :dark="dark">
            <slot name="dateIcon">
              <v-icon>event</v-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>access_time</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker v-model="date" v-bind="datePickerProps" @input="showTimePicker" full-width :dark="dark" class="elevation-0"></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker ref="timer" v-model="time" :dark="dark" v-bind="timePickerProps" full-width class="elevation-0" />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
          <v-btn :dark="dark" text @click.native="clearHandler" class='dateTimeDialogClear'>{{ clearText || $t('DATETIMEPICKER_CLEAR')}}</v-btn>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn :dark="dark" text @click.native="cancelHandler" class='dateTimeDialogCancel'>{{ clearText || $t('DATETIMEPICKER_CANCEL')}}</v-btn>
          <v-btn :dark="!isConfirmLight" :light="isConfirmLight" color="confirm" @click="okHandler" class="elevation-0 dateTimeDialogConfirm">{{ okText || $t('DATETIMEPICKER_CONFIRM') }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { useThemeStore } from '@stores/themeStore'

const DEFAULT_DATE = ''
const DEFAULT_TIME = '00:00 am'
const DEFAULT_DIALOG_WIDTH = 340
export default {
  name: 'date-time-picker',
  model: {
    prop: 'datetime',
    event: 'input'
  },
  props: {
    datetime: {
      type: [String],
      default: undefined
    },
    dark: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    clearText: {
      type: String,
      default: ''
    },
    okText: {
      type: String,
      default: ''
    },
    textFieldProps: {
      type: Object
    },
    datePickerProps: {
      type: Object
    },
    timePickerProps: {
      type: Object
    }
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
      initDateTime: '',
      pickerDateFormat: 'YYYY-MM-DD',
      pickerTimeFormat: 'HH:mm'
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    formattedDatetime() {
      return this.selectedDatetime ? this.selectedDatetime.local().format('MMM Do, YYYY hh:mm a') : ''
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + ' ' + this.time
        if (this.time.length === 5) {
          datetimeString += ':00'
        }
        return moment(datetimeString)
      } else {
        return ''
      }
    },
    dateSelected() {
      return !this.date
    },
    isConfirmLight()
    {
      return useThemeStore().isConfirmLight;
    }
  },
  methods: {
    init() {
      if (!this.datetime) {
          this.initDateTime = '';
          this.date = '';
          this.time = ''
        return
      }
      this.initDateTime = moment(this.datetime);
      
      this.date = this.initDateTime.local().format(this.pickerDateFormat)
      this.time = this.initDateTime.local().format(this.pickerTimeFormat)
    },
    okHandler() {
      this.resetPicker()
      this.$emit('input', this.selectedDatetime.toISOString())
    },
    clearHandler() {
      this.resetPicker()
      this.initDateTime = undefined;
      this.$emit('input', '')
    },
    cancelHandler() {
      this.resetPicker()
      if(this.initDateTime)
      {      
        this.date = this.initDateTime.local().format(this.pickerDateFormat)
        this.time = this.initDateTime.local().format(this.pickerTimeFormat)
        this.$emit('input', this.initDateTime.toISOString())
      }
      else
      {
        this.date = '';
        this.time = '';
        this.clearHandler();
      }
    },
    resetPicker() {
      this.display = false
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    },
    showTimePicker() {
      this.activeTab = 1
    }
  },
  watch: {
    datetime: function() {
      this.init()
    }
  }
}
</script>

<style scoped>
.v-picker.v-card
{
    border-radius: 0px;
}
</style>