<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('PLAYERS_EDIT_DIALOG_TITLE')" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="playerEditDialog">
        <v-form v-model="valid" ref="form">
            <v-text-field v-model="name" :label="$t('PLAYERS_EDIT_DIALOG_NAME')" :rules="nameRules" :counter="maxNameLength" :disabled="isLoading" class="playerEditDialogName" id="playerEditDialogNameInput"/>
            <v-text-field v-model="email" :label="$t('PLAYERS_EDIT_DIALOG_EMAIL')" :rules="emailRules" :counter="maxEmailLength" :disabled="isLoading" class="playerEditDialogEmail" />
            <v-text-field v-model="affiliation" :label="$t('PLAYERS_EDIT_DIALOG_AFFLIATION')" :counter="maxAffiliationLength" :disabled="isLoading" class="playerEditDialogAffiliation" />
            <sorted-select v-if="allowTeamChange" v-model="teamId" :label="$t('PLAYERS_EDIT_DIALOG_TEAM')" :items="displayTeams" item-text="name" item-value="id" :disabled="isLoading" class="playerEditDialogTeam" id="playerEditDialogTeam" />
        </v-form>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IPlayerApiClient } from '@cyber-range/cyber-range-api-ctf-player-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { ITeam, ITeamApiClient } from '@cyber-range/cyber-range-api-ctf-team-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { usePlayerStore } from '@/stores/playerStore';
import { useTeamStore} from '@/stores/teamStore';

@Component({components:{}})
export default class EditPlayerDialog extends Vue 
{
    get teams(): ITeam[]
    {
        return useTeamStore().getTeams
    }

    @Prop(Boolean) value: boolean;
    @Prop(String) playerId:string;
    @Prop(Boolean) allowTeamChange:boolean;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().playerApiClient;
    }
    get teamApiClient(): ITeamApiClient
    {
        return useApiClientStore().teamApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO

    valid = false;
    showConfirmationDialog:boolean = false;
    maxNameLength:number = 100;
    maxEmailLength:number = 256;
    maxAffiliationLength:number = 256;
    nameRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxNameLength)];
    emailRules = [(v)=>Rule.maxLength(v, this.maxNameLength), Rule.notRequiredEmail];
    affiliationRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxAffiliationLength)];
    
    teamIsFull(teamListItem:Partial<ITeam>):boolean
    {
        return !!this.competition?.limits && ((teamListItem?.statistics?.numberOfPlayers || 0) >= this.competition?.limits?.maxPlayersPerTeam);
    }

    displayTeams:ITeam[] = [];
    setDisplayTeams():void
    {
        let result:ITeam[] = [];
        for(let team of this.teams)
        {   
            let displayTeam = {...team};            
            if(this.teamIsFull(team) && this.originalTeamId !== team.id)
            {
                displayTeam['disabled'] = true;
                displayTeam.name = (this.$t('PLAYERS_EDIT_DIALOG_TEAM_IS_FULL',{teamName: team.name}).toString()) as string;
            }
            result.push(displayTeam);
        }
        this.displayTeams=result;
    }

    name:string = '';
    email:string = '';
    teamId:string = '';
    originalTeamId:string = '';
    affiliation:string = '';

    async mounted() 
    {
        if(this.playerId) await this.load()
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        if(value) await this.load()
    }

    async load()
    {
        try
        {
            let player = await this.playerApiClient.getOne(this.playerId);

            this.name = player.name;
            this.email = player.email;
            this.teamId = this.originalTeamId = player.teamId;
            this.affiliation = player.affiliation;

            this.showConfirmationDialog = this.value
            this.setDisplayTeams();
        }
        catch(e)
        {
            this.close();
        }
        
    }
    
    async confirm()
    {
        (<any>this.$refs.form).validate();

        if(!this.valid) return;
        
        await this.playerApiClient.update(this.playerId, {
            name: this.name,
            email: this.email,
            affiliation: this.affiliation
        });
    
        if(this.teamId !== this.originalTeamId)
        {
            //Remove the player from the old team is the player has a team.
            if(this.originalTeamId)
            {
                await this.teamApiClient.kick(this.originalTeamId, this.playerId);
            }

            await this.teamApiClient.join(this.teamId, this.playerId);
            await this.teamApiClient.approve(this.teamId, this.playerId);
        }

        //Asynchronously, refetch player and then fetch updated team statistics
        usePlayerStore().fetchCurrentPlayer().then(()=> setTimeout(()=> useTeamStore().fetchTeams(),1000));

        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>