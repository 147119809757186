<template>
    <confirmation-dialog v-model="showDialog" :title="challenge.name" :confirm="challengeDialogConfirmText" @confirm="confirm" @cancel="cancel" :loading="isLoading" class="challengeDialog">
        <v-col cols="12" v-if="fetchingChallenge">
            <loading />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
            <p class="challengeDialogDescription" v-html="challengeDescription" />
        </v-col>
        <v-col cols="12" v-if="challenge.artifacts" class="pt-0">
            <artifacts :artifacts="challenge.artifacts" :challenge-id="challenge.id" class="artifacts" />
            <hints :challenge="challenge" class="hints" />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
            <v-form ref="form" :lazy-validation="true" @submit.prevent="confirm">
                <component :is="challengeFlagInputComponent" v-model="flag" :challenge="challenge" :disabled="isLoading" class="challengeDialogFlag pt-0" id="challengeDialogFlag" />
            </v-form>
            <span v-if="flagHint" class="grey--text text--darken-2" id="remainingNumberOfAttemptsText">{{ flagHint }}</span>
        </v-col>
        <div slot="titleActions" class="caption">{{$t('CHALLENGE_DIALOG_POINTS', {points:challenge.points})}}</div>
        <template #bottomLeft>
            <v-tooltip v-if="canUpdateChallenge(competition.id, competition.organizationId)" top>
                <template v-slot:activator="{ on }">
                    <v-btn :aria-label="$t('CHALLENGE_DIALOG_EDIT_THIS_CHALLENGE')" icon v-on="on" @click="onEditChallenge" :disabled="isLoading" :dark="isDark" :light="isLight" class="ml-3 editButton" id="editButton">
                        <v-icon>create</v-icon>
                    </v-btn>
                </template>
                <span>{{$t('CHALLENGE_DIALOG_EDIT')}}</span>
            </v-tooltip>
            <accessible-menu v-if="canSaveChallenge(competition.id, competition.organizationId)" offset-y :label="$t('CHALLENGE_DIALOG_SAVE')" class="saveMenu">
                <template #menuToggle="{ on: menuOn, value, listId }">
                    <v-tooltip top  data-testing="save-challenge-tooltip">
                        <template v-slot:activator="{ on: tooltipOn, attrs }">
                            <div v-bind="attrs" v-on="{...tooltipOn}">
                                <v-btn v-on="{...tooltipOn, ...menuOn}" :disabled="isLoading || cannotSaveEnvironmentArtifacts" icon plain small :aria-label="$t('CHALLENGE_DIALOG_SAVE')" :aria-owns="listId" aria-haspopup="true" :aria-expanded="`${value}`" class="saveMenuToggle">
                                    <v-icon>save</v-icon>
                                </v-btn>
                            </div> 
                        </template>
                        <span>{{ saveTooltipText }}</span>
                    </v-tooltip>
                </template>
                <v-list>
                    <v-list-item @click="onPersonalSaveClicked" class="visibility-item">
                        <v-list-item-title>{{$t('CHALLENGE_DIALOG_SAVE_VISIBILITY_PERSONAL')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="canSaveChallengeWithOrganizationalVisibility(competition.organizationId)" @click="onOrganizationalSaveClicked" class="visibility-item">
                        <v-list-item-title>{{$t('CHALLENGE_DIALOG_SAVE_VISIBILITY_ORGANIZATIONAL')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="canSaveChallengeWithGlobalVisibility()" @click="onGlobalSaveClicked" class="visibility-item">
                        <v-list-item-title>{{$t('CHALLENGE_DIALOG_SAVE_VISIBILITY_GLOBAL')}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </accessible-menu>

            <solved-by v-else :statistics="challenge.statistics" />
        </template>
        <template #bottom v-if="competitionStarted && canViewFlagsAndHints">
            <v-btn @click="onTestSubmissionClicked" elevation="0" id="challengeDialogTestButton">{{ $t('CHALLENGE_DIALOG_TEST') }}</v-btn>
        </template>
        <team-required-dialog v-model="showTeamRequiredDialog" />
        <test-challenge-dialog v-if="canViewFlagsAndHints" v-model="showTestChallengeDialog" :challengeId="challengeId" :flag="flag" @confirm="onTestChallengeDialogConfirm"/>
        <save-challenge-dialog v-model="showSaveChallengeDialog" :challengeId="challengeId" :visibility="saveChallengeVisibility" @confirm="onSaveChallengeDialogConfirm" @cancel="onSaveChallengeDialogConfirm" />
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { IChallengeApiClient, IChallenge, ChallengeArtifactType } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import moment from 'moment';
import { selectChallengeFlagInput } from './challengeFlagInput/selectChallengeFlagInput'
import { useThemeStore } from '@/stores/themeStore';
import { LibraryVisibility } from '@cyber-range/cyber-range-api-ctf-library-client';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { useScoreStore } from '@stores/scoreStore';
import { useTeamStore } from '@/stores/teamStore';
import { useSubmissionStore } from '@/stores/submissionStore';

@Component
export default class ChallengeDialog extends Vue 
{  
    @Prop(String) challengeId:string;
    @Prop(Boolean) value:boolean;

    get myTeamId(): string
    {
        return useTeamStore().getMyTeam
    }

    @Ref('form') formRef: Vue;
    
    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get challengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().challengeApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canUpdateChallenge(competitionId:string, organziationId:string): boolean
    {
        return useAuthorizationStore().canUpdateChallenge(competitionId, organziationId);
    }
    canSaveChallenge(competitionId:string, organziationId:string): boolean
    {
        return useAuthorizationStore().canSaveChallenge(competitionId, organziationId);
    }
    canSaveChallengeWithGlobalVisibility(): boolean
    {
        return useAuthorizationStore().canSaveChallengeWithGlobalVisibility();
    }
    canSaveChallengeWithOrganizationalVisibility(organziationId:string): boolean
    {
        return useAuthorizationStore().canSaveChallengeWithOrganizationalVisibility(organziationId);
    }
    // END TODO

    challenge:IChallenge = <IChallenge>{};
    showDialog:boolean = false;
    showSaveChallengeDialog: boolean = false;
    saveChallengeVisibility: LibraryVisibility = LibraryVisibility.Personal;
    showTeamRequiredDialog:boolean = false;
    showTestChallengeDialog:boolean = false;
    fetchingChallenge:boolean = true;
    flag:string|string[] = '';
    remainingNumberOfAttempts = Number.MAX_SAFE_INTEGER;

    get challengeDescription():string
    {
        return this.challenge?.description?.replace(/\n/g, '<br>') ;
    }

    get canViewFlagsAndHints():boolean
    {
        return useAuthorizationStore().canViewFlagsAndHints(this.competition.id, this.competition.organizationId);
    }

    get competitionStarted():boolean
    {
        return !(moment().isBefore(this.competition.settings.startTime));
    }

    get challengeDialogConfirmText()
    {
        return this.competitionStarted ? this.$t('CHALLENGE_DIALOG_CONFIRM') : this.$t('CHALLENGE_DIALOG_TEST');
    }

    get flagHint(): string
    {
        return this.remainingNumberOfAttempts < 10 ? this.$tc('CHALLENGE_DIALOG_REMAINING_NUMBER_OF_ATTEMPTS', this.remainingNumberOfAttempts).toString() : '';
    }

    get cannotSaveEnvironmentArtifacts()
    {
        return (this.challenge.artifacts?.some(a=>a.type===ChallengeArtifactType.Environment) && !useAuthorizationStore().canCreateEnvironmentArtifact(this.challenge.competitionId,this.challenge.organizationId))
    }

    get saveTooltipText()
    {
        return this.cannotSaveEnvironmentArtifacts 
            ? this.$t('CHALLENGE_DIALOG_SAVE_DISABLED_UNAUTHORIZED_ENVIRONMENT_ARTIFACTS')
            : this.$t('CHALLENGE_DIALOG_SAVE')
    }

    get challengeFlagInputComponent()
    {
        return selectChallengeFlagInput(this.challenge);
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        if(value) await this.load();
    }

    @Watch('challenge', { immediate: true })
    async onChallengeChanged(value:IChallenge)
    {
        if(value)
        {
            this.updateRemainNumberOfAttempts();
        }
    }

    onEditChallenge()
    {
        this.$emit('edit', this.challengeId);
        this.close();
    }

    async mounted() 
    {
        this.showDialog = this.value; 

        if(this.showDialog) await this.load();
    }

    async load() 
    {
        try
        {
            this.formRef?.['reset']?.();
            this.flag = '';
            this.fetchingChallenge = true;
            this.challenge = await this.challengeApiClient.getOne(this.challengeId);
            this.showDialog = true;
        }
        finally
        {
            this.fetchingChallenge = false;
        }
    }

    async updateRemainNumberOfAttempts(): Promise<void>
    {
        if(this.myTeamId)
        {
            let statistics = await useSubmissionStore().getTeamChallengeSubmissionStatistics({teamId: this.myTeamId, challengeId: this.challengeId, background: true});

            if(this.challenge.settings?.maxNumberOfAttempts)
            {
                this.remainingNumberOfAttempts = this.challenge.settings.maxNumberOfAttempts - (statistics.failedAttempts + statistics.successfulAttempts);
            }
        }
    }
    
    async confirm()
    {
        if(!this.competitionStarted && this.canViewFlagsAndHints)
        {
            this.showTestChallengeDialog = true;
            return;
        }

        if(!this.myTeamId)
        {
            this.showTeamRequiredDialog = true;
            return;
        }

        if((<any>this.$refs.form).validate() === false) return;

        let result = await this.challengeApiClient.putChallengeSubmission(this.challengeId, this.flag);
        
        this.$emit('result', result);

        if(result.correct)
        {
            useSubmissionStore().fetchSubmissions();
            useScoreStore().fetchTeamsScores();
            this.$emit('confirm', true);
            this.close();
        }
        else
        {
            this.updateRemainNumberOfAttempts();
        }
    }

    async cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }

    onTestSubmissionClicked()
    {
        this.showTestChallengeDialog = true;
    }

    onTestChallengeDialogConfirm()
    {
        this.showTestChallengeDialog = false;
    }

    openSaveChallengeDialog(visibility: LibraryVisibility)
    {
        this.saveChallengeVisibility = visibility;
        this.showSaveChallengeDialog = true;
    }

    onGlobalSaveClicked()
    {
        this.openSaveChallengeDialog(LibraryVisibility.Global);
    }

    onOrganizationalSaveClicked()
    {
        this.openSaveChallengeDialog(LibraryVisibility.Organizational);
    }
    
    onPersonalSaveClicked()
    {
        this.openSaveChallengeDialog(LibraryVisibility.Personal);
    }

    onSaveChallengeDialogConfirm()
    {
        this.showSaveChallengeDialog = false;
    }
}
</script>