import NotificationEvent from '@interfaces/NotificationEvent';
import { IChallenge, ChallengeFilter, ChallengePage, IChallengeFilter } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import Config from '@/config';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { useNotificationStore } from '@stores/notificationStore';
import { useApiClientStore } from './apiClientStore';
import { useCompetitionStore } from './competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { defineStore } from 'pinia';

function sortChallenge(a:IChallenge, b:IChallenge): number
{
    return a.points < b.points ? -1: 1;
}

export const useChallengeStore = defineStore('challengeStore', {
    state: () =>
    ({
        challengesFetched: false,
        challenges: {} as Record<string, IChallenge[]>,
    }),
    getters: 
    {
        isChallengesFetched: (state): boolean =>
        {
            return state.challengesFetched;
        },
        getChallenge: (state) => (id?:string): IChallenge|undefined => 
        {
            for (const challenges of Object.values(state.challenges))
            {
                for (const challenge of challenges)
                {
                    if (challenge.id === id)
                    {
                        return challenge;
                    }
                }
            }
            return undefined
        },
        getChallenges: (state) => (category?:string):IChallenge[] => 
        {
            if(category)
            {
                let challenges = state.challenges[category] || [];
                challenges.sort(sortChallenge);
                return challenges;
            }

            //If no category is given, return all challenges.
            let allChallenges = [];
            for(let category of Object.keys(state.challenges))
            {
                allChallenges = allChallenges.concat(state.challenges[category]);
            }
            allChallenges.sort(sortChallenge);
            return allChallenges;
        },
        getChallengeCategories: (state):string[] =>
        {
            let result:string[] = [];

            let competition = useCompetitionStore().currentCompetition;

            //Get categories from the competition
            let competitionCategories = competition?.categories?.sort((a,b)=>a.index > b.index ? 1 : -1).map(c => c.name) || [];
            
            //Get categories from challenges
            let challengeCategories = Object.keys(state.challenges);
            challengeCategories.sort();

            //Remove categories from the competition that no longer have any challenge
            for(let category of competitionCategories)
            {
                if(challengeCategories.includes(category))
                {
                    result.push(category);
                }
            }

            //Append with unordered categories from challenges
            for(let category of challengeCategories)
            {
                if(!result.includes(category))
                {
                    result.push(category);
                }
            }

            return result.map(item => item.toLowerCase());
        }
    },
    actions: 
    {
        setChallenges(challenges:IChallenge[]): void
        {
            this.challenges = {};

            for(let challenge of challenges)
            {
                let category = challenge.category?.toLowerCase();
                
                if(!(category in this.challenges))
                {
                    this.challenges[category] = [];
                }
                this.challenges[category].push(challenge);
            }

            for(let category of Object.keys(this.challenges))
            {
                this.challenges[category].sort(sortChallenge);
            }
            
            this.challengesFetched = true;
        },
        clearChallenges(): void{
            this.challenges = {};
            this.challengesFetched = false;
        },
        async fetchChallenges(payload?: {background: boolean}): Promise<void> 
        {
            let client = payload?.background ? useApiClientStore().backgroundChallengeApiClient : useApiClientStore().challengeApiClient;
            let page:ChallengePage;
            let challenges:IChallenge[] = [];
            let competition:ICompetition  = useCompetitionStore().currentCompetition;

            if(competition?.settings?.endTime < new Date().toISOString() && !useAuthorizationStore().canCreateChallenge(competition.id,competition.organizationId))
            {
                return;
            }
            let competitionId  = competition.id;
            let filter:IChallengeFilter = new ChallengeFilter({competitionId: competitionId, limit: Config.DEFAULT_FETCH_SIZE});
            do
            {
                page = await client.get(filter);
                filter.token = page.nextPageToken;
                challenges = challenges.concat(page.items);
            }
            while(filter.token)

            this.setChallenges(challenges);

            if(!useNotificationStore().isSubscribed(NotificationEvent.ChallengesUpdated))
            {
                useNotificationStore().subscribe({
                    event: NotificationEvent.ChallengesUpdated, 
                    callback: ()=> this.fetchChallenges({competitionId: competitionId, background: true})
                }); 
            };
        }
    }
});
