import 'vuetify/dist/vuetify.min.css';

import 'babel-polyfill';
import { TrackJS } from 'trackjs';
import { initializeAllGlobalFilters } from '@entities/filters/filter';
import createMessages from '@entities/strings/strings';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuetify from 'vuetify';
import Config from '@/config';
import Main from './components/Main.vue';
import VueRouter from 'vue-router';
import path from 'path';
import { DefaultTheme } from './themes/themes/defaultTheme';
import Route from '@/interfaces/route';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import ITheme from '@/interfaces/iTheme';
import SplashScreen from '@components/SplashScreen.vue';
import querystring from 'querystring';
import VueGoogleCharts from 'vue-google-charts/legacy'
import VueMeta from 'vue-meta';
import { Pinia, PiniaVuePlugin, createPinia } from 'pinia';
import { useThemeStore } from '@/stores/themeStore';
import { useCompetitionStore } from './stores/competitionStore';


async function getCurrentTheme(pinia:Pinia): Promise<ITheme>
{
	let subdomain = location.hostname.split('.')[0];
	let themeId = querystring.parse(location.search?.substring(1))['themeid'] as string;
	let competition:ICompetition;

	const themeStore = useThemeStore(pinia);

	try
	{
		competition = await useCompetitionStore().fetchCompetition({subdomain: subdomain});
	}
	catch(e)
	{
		console.error(`Failed to retrieve a theme for '${subdomain}'. Use the default theme instead.`, e);
	}

	themeStore.setCurrentTheme(themeId || competition?.settings?.themeId || DefaultTheme.Id);

	return themeStore.getCurrentTheme;
}

function loadThemedComponents(currentTheme:ITheme): void
{
	// Dynamically search for themed components https://vitejs.dev/guide/features#glob-import
	const componentImports = import.meta.glob('./components/**/*.*.vue', {eager: true});

	let defaultComponents = {};
	let themeComponents = {};

	// Extract components for the default theme and the current theme 
	for(let fileName in componentImports)
	{
		const componentConfig = componentImports[fileName];
	
		//Format: componentName.themeId.vue
		let [componentName, forTheme] = path.basename(fileName, path.extname(fileName)).split('.', 2);

		forTheme = forTheme.toLowerCase();
		
		if(forTheme === DefaultTheme.Id)
		{
			defaultComponents[componentName] = componentConfig.default || componentConfig;
		}
		else if(forTheme === currentTheme.id)
		{
			themeComponents[componentName] = componentConfig.default || componentConfig;
		}
	};

	// The components to use are based on the default theme and the current theme
	let toUseComponents = Object.assign(defaultComponents, themeComponents);
	
	// Globally register selected components
	for(let componentName of Object.keys(toUseComponents))
	{
		Vue.component(componentName, toUseComponents[componentName]);

		// Register page components to use for routes
		if(componentName in Route)
		{
			Route[componentName].component = toUseComponents[componentName];
		}
	}
}

async function createVueApplication()
{ 
	// Pinia - must be the executed first so the router initialized below can access stores.
	Vue.use(PiniaVuePlugin);
	let pinia = createPinia();

	Vue.use(VueRouter);
	Vue.use(VueGoogleCharts);

	initializeAllGlobalFilters();
	Vue.use(VueI18n);
	const i18n = new VueI18n({
		locale: process.env.locale || 'en',
		messages: createMessages(Config.IS_VACR ? 'VACR' : 'USCR')
	});
	
	// Theme
	let currentTheme = await getCurrentTheme(pinia);
	loadThemedComponents(currentTheme);

	// Create a router.  It is done here so that the Route object 
	// contains the right components based on the current theme.
	let router = (await import ('@/router')).default

	Vue.use(VueMeta);

	// Create Vue application
	new Vue({
		el: '#app',
		pinia,
		router,
		i18n,
		render: (h) => h(Main),
		vuetify: new Vuetify({
			theme: {
				dark: currentTheme.isDark,
				themes: {
					light: currentTheme.colors,
					dark: currentTheme.colors
				},
				options: {
					customProperties: true
				}
			}
		})
	});
	document.title = Config.APP_TITLE;
}

Vue.use(Vuetify);
Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
	// Log properties passed to the component if there are any
	if (vm.$options.propsData) {
		console.log('Props passed to component', vm.$options.propsData);
	}

	// Emit component name and also the lifecycle hook the error occured in if present
	let infoMessage = `Error in component: <${vm.$options['_componentTag']} />\n`;
	if (info) {
		infoMessage += `Error occurred during lifecycle hook: ${info}\n`;
	}

	// This puts the additional error information in the Telemetry Timeline
	console.log(infoMessage);
	console.log(err);
	
	// Track the native JS error
	TrackJS.track(err);
};

// Show a splash screen while loading the real Vue app.
new Vue({
	el: '#app',
	render: (h) => h(SplashScreen),
	vuetify: new Vuetify()
}).$mount('#app')

Config.load(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/env.json?t=${new Date().getTime()}`).then(()=>createVueApplication());