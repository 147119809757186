<script lang="ts">
import Vue from 'vue';
import BaseLanding from './Landing.vue';
import { mixins } from 'vue-class-component';

export default class Landing extends mixins(BaseLanding) 
{   
    
}
</script>

<style scoped>
    :deep(#landingButtons .v-btn)
    {
        color: black;
        outline: 1px solid black;
    }

    #competitionName,
    #competitionTimeframe,
    .countdown
    {
        letter-spacing: 0.05em !important;
    }    
</style>