<template>
    <div>

        <v-row class="mt-0">
            <v-col cols="auto">
                <h1>{{$t('CHALLENGES_TITLE')}}</h1>
            </v-col>
            <v-spacer/>
            <v-col cols="1">
                <add-challenge-menu v-if="canCreateChallenge(competition.id, competition.organizationId)" @added='onChallengesAdded' />
            </v-col>
            <v-col cols="12" class="ma-0 pa-0 loadingRow">
                <loading v-if="isLoading"/>
            </v-col>

            <v-col v-if="isChallengesFetched && challengeCategories.length === 0" cols="12" align="center" class="mt-10 pt-10">
                <template v-if="canUpdateCompetition(competition.id, competition.organizationId)">
                    <p id="noChallengesAdmin">{{$t('NO_CHALLENGES_ADMINS')}}</p>
                </template>
                <template v-else-if="competitionStartTime && !competitionHasStarted">
                    <p class="mb-4">{{$t('CHALLENGES_COUNTDOWN')}}</p>
                    <countdown-clock id="challengesCountdown" :end="competitionStartTime" @timed="onCompetitionStarted"/>
                </template>
                <template v-else>
                    <p id="noChallengesPlayer">
                        <template v-if="refetchCompetitionAfterStarted">
                            {{$t('NO_CHALLENGES_PLAYERS')}}
                        </template>
                        <template v-else>
                            {{$t('FETCHING_CHALLENGES')}}
                        </template>
                    </p>
                    <v-btn icon text @click="onRefreshClicked" :disabled="isLoading">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                </template>
            </v-col>

            <v-col v-if="canUpdateCompetition(competition.id, competition.organizationId) || !competitionHasEnded" cols="12">
                <v-toolbar :dark='isDark' class="elevation-0" id="challengeCategories">
                    <v-toolbar-items class="pr-5">
                        <draggable class="draggable" :disabled="!canUpdateCompetition(competition.id, competition.organizationId)" :list="challengeCategories" @start="onDragging" @end="onDragged">
                            <v-btn text v-for="category in challengeCategories" :key="category" :dark='isDark' :id="`challengeCategory_${category}`"
                                :to="{name:categorizedChallengeRouteName,params:{category:category}}" class="categoryButton">
                                <a :href='category'>
                                    {{category}}
                                </a>
                            </v-btn>
                        </draggable>
                    </v-toolbar-items>
                </v-toolbar>
            </v-col>

        </v-row>


        <v-col v-if="!canUpdateCompetition(competition.id, competition.organizationId) && competitionHasEnded" cols="12" align="center" class="mt-10 pt-10 mb-10 pb-10">
            <p id="competitionHasEnded" class="mb-10 mt-10 pb-10 pt-10">{{$t('COMPETITION_HAS_ENDED')}}</p>
        </v-col>
        <router-view v-else class="view"></router-view>


        <v-row v-if="(competitionStartTime && competitionHasStarted) || !competitionStartTime">
            <v-col v-if="isScoreboardEnabled" cols="12" md="4" lg="3">
                <score-window />
            </v-col>
            <v-col cols="12" :md="isScoreboardEnabled ? (competitionEndTime ? 4 : 8) : 8"  :lg="isScoreboardEnabled ? (competitionEndTime ? 6 : 9) : 9">
                <activity-window />
            </v-col>
            <v-col cols="12" md="4"  lg="3" v-if="competitionEndTime">
                <countdown-window id="competitionEndingCountdown" :end="competitionEndTime" @timed="onCompetitionEnded" />
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import Route from '@/interfaces/route';
import draggable from 'vuedraggable'
import { ICompetition, ICompetitionApiClient, Competition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';
import TitleStrings from '@entities/strings/definitions/titleStrings';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useChallengeStore } from '@stores/challengeStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { useSubmissionStore } from '@/stores/submissionStore';

const CATEGORIES_UPDATE_DELAYED_IN_MS = 2000;
const FETCH_CHALLENGE_DELAYED_IN_MS = 1000;

@Component({
    components:{draggable},
    metaInfo: { title: TitleStrings.en.TITLE_CHALLENGES }
})
export default class Challenges extends Vue 
{
    get challengeCategories(): string[]
    {
        return useChallengeStore().getChallengeCategories
    }
    get isChallengesFetched(): boolean
    {
        return useChallengeStore().isChallengesFetched
    }
    
    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get competitionApiClient(): ICompetitionApiClient
    {
        return useApiClientStore().competitionApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canUpdateCompetition(competitionId:string, organizationId:string):boolean
    {
        return useAuthorizationStore().canUpdateCompetition(competitionId, organizationId);
    }
    canCreateChallenge(competitionId: string, organizationId:string): boolean
    {
        return useAuthorizationStore().canCreateChallenge(competitionId, organizationId);
    }
    // END TODO

    dragSubject:Subject<void>;
    dragSubscription:Subscription; 
    competitionStartTime:string = '';
    competitionEndTime:string = '';
    competitionHasStarted: boolean = false;
    competitionHasEnded:boolean = false;
    refetchCompetitionAfterStarted:boolean = false;

    get categorizedChallengeRouteName()
    {
        return Route.CategorizedChallenges.name;
    }

    get isScoreboardEnabled()
    {
        return !this.competition?.settings?.hideDashboard;
    }

    async mounted() 
    {
        const submissionStore = useSubmissionStore();
        if(!submissionStore.isSubmissionFetched)
        {
            submissionStore.fetchSubmissions({background:true});
            submissionStore.fetchMyTeamsChallengesStatistics({background:true});
        }

        if(!this.isChallengesFetched)
        {
            await useChallengeStore().fetchChallenges();
        }

        this.dragSubject = new Subject<void>();
        this.dragSubscription = this.dragSubject
                                    .pipe(debounceTime(CATEGORIES_UPDATE_DELAYED_IN_MS))
                                    .subscribe({next:this.updateCategories})
        
        this.renderChallenges();
    }

    beforeDestroy() 
    {
        this.dragSubject.unsubscribe();
    }

    onDragging()
    {
        this.dragSubject.next();
    }
    
    onDragged()
    {
        this.dragSubject.next();
    }

    async onChallengesAdded(challenges:IChallenge[])
    {
        await useChallengeStore().fetchChallenges();
        this.$router.push({name: Route.CategorizedChallenges.name, params: {category: challenges[0].category.toLowerCase()}});
    }

    onCompetitionEnded()
    {
        this.competitionHasEnded = true;
    }

    renderChallenges()
    {
        this.competitionStartTime = this.competition.settings?.startTime || '';
        this.competitionHasStarted = moment().isAfter(this.competitionStartTime);
        this.refetchCompetitionAfterStarted = (this.competitionStartTime && this.competitionHasStarted) || !this.competitionStartTime;

        this.competitionEndTime = this.competition.settings?.endTime || '';

        if(!this.$route?.params?.category && this.challengeCategories.length > 0)
        {
            this.$router.replace({...Route.CategorizedChallenges, params: {category: this.challengeCategories[0]}});
        }
    }

    async onRefreshClicked()
    {
       await useChallengeStore().fetchChallenges(); 
       this.renderChallenges();
    }

    async onCompetitionStarted()
    {
        this.competitionHasStarted = true;

        //Delay challenges fetching to work around any time sync between UI and APIs.
        await new Promise((resolve)=>setTimeout(resolve, FETCH_CHALLENGE_DELAYED_IN_MS));
        
        await useChallengeStore().fetchChallenges();
        this.renderChallenges();

        this.refetchCompetitionAfterStarted = true;
    }

    async updateCategories()
    {
        let categories = this.challengeCategories.map((name:string, index:number) => ({name, index}));
        await this.competitionApiClient.update(this.competition.id, new Competition(<ICompetition>{categories}));
        await useCompetitionStore().refreshCurrentCompetition();
    }
}
</script>

<style scoped>
@import "./challenges.css";
</style>
<style>
#challengeCategories .v-toolbar__content{
    height: auto !important;
}
</style>