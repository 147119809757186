import IActivity from "@interfaces/iActivity";
import { ISubmission, SubmissionStatus, SubmissionType } from '@cyber-range/cyber-range-api-ctf-submission-client';
import { useTeamStore } from "@/stores/teamStore";
import { useChallengeStore } from "@/stores/challengeStore";

export default class Activity
{
    id:string;
    timestamp:string;
    message:string;

    constructor(activity?:Partial<IActivity>)
    {
        this.id = activity?.id;
        this.timestamp = activity?.timestamp;
        this.message = activity?.message;
    }

    static fromSubmission(submission:ISubmission): IActivity[]
    {
        if(submission.type !== SubmissionType.Flag || submission.status !== SubmissionStatus.Correct) return [];
        
        let team = useTeamStore().getTeam(submission.teamId);
        
        if(team?.hidden) return [];

        //Note: A default team name is needed for new teams that teamStore has yet to fetched or for the team has been deleted.
        let teamName = team?.name || 'A mystery team'; 
        let message = `${teamName} has captured ${submission.challengeName}.`;

        const activities = [new Activity({id: submission.id, timestamp: submission.createdTimestamp, message: message})]

        // Unlock Activities
        const unlocks = useChallengeStore().getChallenge(submission.challengeId)?.unlocks;
        for (const i in unlocks || [])
        {
            const challengeId = unlocks[i]
            const challengeName = useChallengeStore().getChallenge(challengeId)?.name;
            activities.push(new Activity({
                id: `unlocks-${submission.teamId}-${challengeId}`,
                timestamp: submission.createdTimestamp,
                message: `${teamName} has unlocked ${challengeName}.`
            }));
        }

        return activities;
    }
}