<template>
    <v-row wrap>
         <draggable class="draggable" :disabled="!editable" :list="hints" @end="onDragged">
            <v-col cols="12" v-for="(hint, index) of hints" :key="hint.id" class="pt-0 pb-0 mb-0 mt-1 hints">
                <v-icon class="mr-2" :dark="isDark" :light="isLight">help_outline</v-icon>
                <v-btn text class="pa-0 ma-0 text-none" @click="()=>onClicked(index)" :disabled="disabled">{{$t('HINT_TITLE', {cost:hint.cost})}}</v-btn>
            </v-col>
         </draggable>
        <v-col cols="12" v-if="editable" class="pt-0 pb-0 mb-0 mt-1 addHint">
            <v-icon class="mr-2" :dark="isDark" :light="isLight">add</v-icon> 
            <v-btn text class="pa-0 ma-0 text-none" @click="onAddClicked()" :disabled="disabled">{{$t('HINT_ADD')}}</v-btn>
        </v-col>
        <hint-dialog v-model="showHint" :challengeId="challenge.id" :hint="selectedHint" class="hintDialog"/>
        <add-edit-hint-dialog v-model="showAddHint" @confirm="onHintAdded" @deleted="onHintDeleted" :hint="selectedHint" :challengeId="challenge.id" class="addEditHintDialog"/>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IChallengeArtifact, ChallengeArtifactType, IChallenge, IHint, Hint } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable'
import Config from '@/config';
import { useThemeStore } from '@/stores/themeStore';

let byHintIndex = (a:IHint,b:IHint)=>a.index-b.index;

@Component({components:{draggable}})
export default class Hints extends Vue 
{  
    @Prop(Object) challenge:IChallenge;
    @Prop(Boolean) editable:boolean;
    @Prop(Boolean) disabled:boolean;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    // END TODO

    hints:IHint[] = [];
    showHint:boolean = false;
    showAddHint:boolean = false;
    selectedHintIndex:number = -1;
    selectedHint:IHint = new Hint();

    onClicked(index:number)
    {
        this.selectedHintIndex = index;
        this.selectedHint = this.hints[this.selectedHintIndex];

        if(this.editable)
        {
            this.showAddHint = true;
        }
        else
        {
            this.showHint = true;
        }
    }

    @Watch('challenge')
    onHintsChanged()
    {
        this.load();
    }

    onAddClicked()
    {
        this.selectedHint = new Hint();
        this.selectedHintIndex = -1;
        this.showAddHint = true;
    }

    onHintAdded(hint:IHint)
    {
        if(hint.id)
        {
            //Existing hint
            let index = this.hints.findIndex(h => h.id === hint.id);
            this.hints[index] = hint;    
        }
        else
        {
            //New hint

            //Assigning an id so we can distinguish one from another (when editing but before saving it)
            hint.id = `${Config.TEMP_HINT_ID_PREFIX}${Date.now()}`;

            this.hints.push(hint);
        }

        this.hints = this.hints.sort(byHintIndex);

        this.$emit('value', this.hints);
    }

    onHintDeleted()
    {
        this.hints.splice(this.selectedHintIndex, 1);

        this.$emit('value', this.hints);
    }

    onDragged()
    {
        //Update hint's indexes
        for(let i = 0; i<this.hints.length; i++)
        {
            this.hints[i].index = i;
        }
        this.$emit('value', this.hints);
    }

    mounted() 
    {
        this.load();
    }

    load()
    {
        if(this.challenge.hints)
        {
            this.hints = this.challenge.hints.sort(byHintIndex);
        }
    }
}
</script>