import IActivity from '@interfaces/iActivity';
import { defineStore } from 'pinia';

export const useActivityStore = defineStore('activityStore', {
    state: () =>
    ({
        _activityIds: new Set<string>(),
        _activities: [] as IActivity[],
    }),
    getters:
    {
        getActivities: (state): IActivity[] => [...state._activities].reverse(),
    },
    actions:
    {
        addActivity(...activities: IActivity[]): void
        {
            for (const activity of activities)
            {
                // Prevent duplicate activities. e.g. Team A unlocked challenge B, since multiple challenges can allow a team to unclock another challenge
                if (!this._activityIds.has(activity.id))
                {
                    this._activities.push(activity);
                    this._activityIds.add(activity.id)
                }
            }
        },
        resetActivities(): void
        {
            this._activities = [];
            this._activityIds.clear();
        },
    }
});
