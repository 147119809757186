<template>
    <div>
        <v-menu left offset-y>
            <template v-slot:activator="{ on, value }">
                <v-badge :content="alerts.length" :value="alerts.length" overlap color="red">
                    <v-avatar v-on="on" size="32" class="avatar" :dark='isDark'>
                        <v-btn text icon id="headerAvatar" :dark='isDark' :aria-label="$t('HEADER_ACCOUNT_MENU')" aria-owns="headerProfile" aria-haspopup="true" :aria-expanded="`${value}`">
                            <img v-if="avatar" :src="avatar" alt="avatar" />
                            <v-icon v-else size="32">account_circle</v-icon>
                        </v-btn>
                    </v-avatar>
                </v-badge>
            </template>
            <v-card outlined id="headerProfile" :dark='isDark' class="headerProfile">
                <v-card-title>
                    <v-list>
                        <v-list-item class="userProfile">
                            <v-list-item-avatar>
                                <v-avatar size="48">
                                    <img v-if="avatar" :src="avatar" alt="avatar">
                                    <v-icon v-else size="48">account_circle</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="name">{{ name }}</v-list-item-title>
                                <v-list-item-subtitle class="email">{{ email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="playerId" class="playerProfile">
                            <v-list-item-avatar />
                            <v-list-item-content>
                                <div class="caption">{{$t('HEADERS_PLAYER_PROFILE_TITLE')}}</div>
                                <v-list-item-title class="name">{{ player.name }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <div class="email">{{ player.email }}</div>
                                    <div v-if="player.affiliation" class="affiliation">{{ player.affiliation }}</div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-title>

                <v-divider></v-divider>
                <v-card-actions class="alerts pa-0 ma-0">
                    <v-list v-if="alerts.length > 0">
                        <v-list-item :key="alert.id" v-for="alert of alerts" @click="onAlertClicked(alert)" scrollable>
                            <v-list-item-avatar>
                                <v-icon color="warning">warning</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="alert">{{alert.message}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-actions>
                <v-divider v-if="alerts.length > 0"></v-divider>

                <v-card-actions>
                    <v-container grid-list-xs text-right pa-0>
                        <v-row row wrap>
                            <v-col v-if="myTeamId && playerId && allowPlayerNameChange && canUpdatePlayer(competition.id, competition.organizationId)" cols="12" class="pa-2">
                                <v-btn text @click="onEditProfileClicked" class="headerEditProfile" :dark="isDark">{{$t('HEADER_EDIT_PROFILE')}}</v-btn>
                            </v-col>
                            <v-col cols="12" class="pa-2">
                                <v-btn text @click="logoutClicked" id="headerLogout" :dark='isDark'>{{$t('HEADER_LOGOUT')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-menu>
        <edit-player-dialog v-model="showEditProfileDialog" :playerId="playerId" @confirm="refresh" />
        <joining-request-alert-dialog v-model="showJoiningRequestAlertDialog" :alert="selectedAlert" />
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Route from '@/interfaces/route';
import { IPlayer } from '@cyber-range/cyber-range-api-ctf-player-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import IAlert from '@/interfaces/iAlert';
import { useThemeStore } from "@/stores/themeStore";
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { usePlayerStore } from "@/stores/playerStore";
import { useTeamStore } from "@/stores/teamStore";
import { useAlertStore } from "@/stores/alertStore";

@Component({
    components: { }
})
export default class AvatarMenu extends Vue {
    messages:string[] = [];

    get myTeamId(): string
    {
        return useTeamStore().getMyTeam
    }
    get alerts(): IAlert[]
    {
        return useAlertStore().getAlerts;
    }
    get player(): IPlayer
    {
        return usePlayerStore().getCurrentPlayer;
    }
    get isTeamsFetched(): boolean
    {
        return useTeamStore().isTeamsFetched
    }
    get isTeamsFetching(): boolean
    {
        return useTeamStore().isTeamsFetching
    }

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get name(): string
    {
        return useAuthenticationStore().identityName;
    }
    get email(): string
    {
        return useAuthenticationStore().identityEmail;
    }
    get avatar(): string
    {
        return useAuthenticationStore().identityAvatar;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canUpdatePlayer(competionId:string, organizationId:string): boolean
    {
        return useAuthorizationStore().canUpdatePlayer(competionId, organizationId)
    }
    // END TODO

    selectedAlert:IAlert = <IAlert>{};
    showEditProfileDialog = false;
    showJoiningRequestAlertDialog = false;

    get playerId():string
    {
        return this.player?.id;
    }

    get allowPlayerNameChange(): boolean
    {
        return this.competition.settings?.allowPlayerNameChange || false;
    }

    async mounted()
    {
        await this.refresh();
    }

    async refresh()
    {
        if(this.competition && this.player === undefined)
        {
            await usePlayerStore().fetchCurrentPlayer();
        }

        const alertStore = useAlertStore();
        if(this.isTeamsFetching || this.isTeamsFetched)
        {
            alertStore.fetchAlerts();
        }
        else
        {
            await useTeamStore().fetchTeams();
            alertStore.fetchAlerts();
        }
    }

    onEditProfileClicked()
    {
        this.showEditProfileDialog = true;
    }

    async logoutClicked()
    {
        await useAuthenticationStore().logout({redirect: true, reload: this.$route?.name === Route.Competitions.name, router: this.$router});
    }

    onAlertClicked(alert:IAlert)
    {
        this.selectedAlert = alert;
        this.showJoiningRequestAlertDialog = true;
    }
}
</script>

<style scoped>
.v-toolbar__content .avatar .v-btn
{
    width: 32 !important;
    height: 32 !important;
}
.avatar img
{
    max-height:32px;
}
.headerProfile
{
    background-color: var(--v-application-base);
}
.headerProfile .v-sheet{
    background-color: initial;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
}
.headerProfile .alerts
{
    max-height: 220px; 
    overflow: auto !important;
    display: block;
}
.headerProfile .alerts .v-list-item__content
{
    max-width: 400px;
}
</style>