<template>
    <confirmation-dialog v-model="showConfirmationDialog" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="teamLeaveDialog">
        {{$t('TEAM_LEAVE_DIALOG_MESSAGE')}}
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ITeamApiClient } from '@cyber-range/cyber-range-api-ctf-team-client';
import { IPlayer } from '@cyber-range/cyber-range-api-ctf-player-client';
import { useApiClientStore } from '@stores/apiClientStore';
import { useTeamStore } from '@/stores/teamStore';
import { usePlayerStore } from '@/stores/playerStore';

@Component({components:{}})
export default class LeaveTeamDialog extends Vue 
{  
    @Prop(Boolean) value: boolean;

    get myPlayer(): IPlayer
    {
        return usePlayerStore().getCurrentPlayer;
    }
    get myTeamId(): string
    {
        return useTeamStore().getMyTeam
    }

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get teamApiClient(): ITeamApiClient
    {
        return useApiClientStore().teamApiClient;
    }
    // END TODO
    
    showConfirmationDialog:boolean = false;

    mounted() 
    {
        this.load()
    }

    @Watch('value')
    onValueChanged(value:boolean)
    {
        this.load()
    }
    
    load()
    {
        this.showConfirmationDialog = this.value
    }

    async confirm()
    {
        await this.teamApiClient.reject(this.myTeamId, this.myPlayer.id);

        await window.location.reload();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>