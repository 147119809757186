<template>
    <v-row class="mt-0">
        <v-col cols="auto">
            <h1>{{$t('SCOREBOARD_TITLE')}}</h1>
        </v-col>
        <v-spacer/>
        <v-col cols="1">
            <v-tooltip v-if="isLogin && canManageSubmission(competition.id, competition.organizationId)" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon outlined v-on="on" @click="onExportClicked" :disabled="isLoading || isExporting" :dark="isDark" :light="isLight" class="ml-3 exportButton" id="exportButton" :aria-label="$t('SCOREBOARD_EXPORT')">
                        <v-icon>save_alt</v-icon>
                    </v-btn>
                </template>
                <span>{{$t('SCOREBOARD_EXPORT')}}</span>
            </v-tooltip>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0 loadingRow">
            <loading v-if="isForegroundFetching" class="scoreboardLoading" />
            <loading v-else-if="isBackgroundFetching" class="scoreboardUpdating" :message="$t('SCOREBOARD_UPDATE')" />
        </v-col>
        <v-col cols="12" v-if="isScoreboardHidden" data-testing="scoreboard-hidden-notification">
            <v-alert class="alphaBackground">{{ $t('SCOREBOARD_HIDDEN_ALERT_TEXT') }}</v-alert>
        </v-col>
        <template v-if="isReady">
            <v-col cols="12">
                <score-chart class="mb-5 scoreChart" :isDark="isDark" />
            </v-col>
            <v-col cols="12">
                <score-table :alphaBackground="true" :showHeader="true" class="mt-5 scoreTable" />
            </v-col>
        </template>
        <v-col v-if="!isFetching && !isReady" cols="12" align="center" class="ma-5 pa-5">
            <p class="scoreboardError">
                {{$t('SCOREBOARD_ERROR')}}
            </p>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { Watch } from 'vue-property-decorator';
import Route from '@interfaces/route';
import { csvExport } from '@/utils/csvExport';
import ExportedSubmission from '@/entities/exportedSubmission';
import TitleStrings from '@/entities/strings/definitions/titleStrings';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { useScoreStore } from '@stores/scoreStore';
import { useTeamStore } from '@/stores/teamStore';
import { useSubmissionStore } from '@/stores/submissionStore';

@Component({ metaInfo: { title: TitleStrings.en.TITLE_SCOREBOARD } })
export default class Scoreboard extends Vue 
{   
    get isTeamsFetched(): boolean
    {
        return useTeamStore().isTeamsFetched
    }

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get isLogin(): boolean
    {
        return useAuthenticationStore().isLogin;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canManageSubmission(competitionId: string, organizationId:string): boolean
    {
        return useAuthorizationStore().canManageSubmission(competitionId, organizationId);
    }
    // END TODO
    
    isExporting = false;
    isFetching = true;
    isReady = false;
    isForegroundFetching = false;
    isBackgroundFetching = false;

    get isScoreboardHidden()
    {
        return !!this.competition?.settings?.hideDashboard;
    }

    @Watch('competition.settings.hideDashboard')
    onCompetitionSettingHideDashboardChanged(hideDashboard:boolean)
    {
        if(hideDashboard) 
        {
            this.$router.replace(this.isLogin ? Route.Challenges : Route.Landing);
        }
    }

    @Watch('competition.settings.allowUnregisteredUsersToViewDashboard')
    onCompetitionSettingAllowUnregisteredUsersToViewDashboardChanged(allowUnregisteredUsersToViewDashboard:boolean)
    {
        if(!allowUnregisteredUsersToViewDashboard && !this.isLogin) 
        {
            this.$router.replace(Route.Landing);
        }
    }

    async onExportClicked()
    {
        try
        {
            this.isExporting = true;

            let exportedSubmissions = await useSubmissionStore().exportSubmissions();

            await csvExport(
                    ExportedSubmission.toCsvHeaders(),
                    exportedSubmissions.map(exportedSubmission => exportedSubmission.toCsvRow()),
                    `ctf_scoreboard.csv`);
        }
        finally
        {
            this.isExporting = false;
        }
    }

    async created()
    {
        const submissionStore = useSubmissionStore();
        try
        {
            let foregroundPromises = [];
            let backgroundPromises = [];

            if(!useScoreStore().isTeamScoresFetched)
            {
                foregroundPromises.push(useScoreStore().fetchScores());
            }
            else
            {
                backgroundPromises.push(useScoreStore().fetchScores({background:true}));
            }

            if(!this.isTeamsFetched) 
            {
                foregroundPromises.push(useTeamStore().fetchTeams());
            }
            else
            {
                backgroundPromises.push(useTeamStore().fetchTeams({background:true}));
            }

            if(!submissionStore.isSubmissionFetched)
            {
                foregroundPromises.push(submissionStore.fetchSubmissions());
            }
            else
            {
                backgroundPromises.push(submissionStore.fetchSubmissions({background:true}));
            }

            if(foregroundPromises.length > 0)
            {
                this.isForegroundFetching = true;
                try
                {
                    await Promise.all(foregroundPromises);
                }
                finally
                {
                    this.isForegroundFetching = false;
                }
            }

            //All data should be fetched and ready to show the scoreboard.
            this.isReady = true;
            
            //Continue to fetch more data in the background. This is to get the most up-to-date scores.
            if(backgroundPromises.length > 0)
            {
                this.isBackgroundFetching = true;

                try
                {
                    await Promise.all(backgroundPromises);
                }
                finally
                {
                    this.isBackgroundFetching = false;
                }
            }
        }
        catch{}
        finally
        {
            this.isFetching = false;
        }
    }
}
</script>
<style scoped>
.loadingRow{
    min-height: 25px;
}
</style>