import IAlert from '@interfaces/iAlert';
import { ITeam } from '@cyber-range/cyber-range-api-ctf-team-client';
import { IPlayerApiClient, PlayerFilter, IPlayerPage, PlayerStatus } from '@cyber-range/cyber-range-api-ctf-player-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import JoiningRequestAlert from '../entities/joiningRequestAlert';
import NotificationEvent from '@/interfaces/NotificationEvent';
import Config from '@/config';
import { useNotificationStore } from '@stores/notificationStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useTeamStore } from './teamStore';
import { defineStore } from 'pinia';

export const useAlertStore = defineStore('alertStore', {
    state: () =>
    ({
        alertById: new Set<string>(),
        alerts: [] as IAlert[]
    }),
    getters: 
    {
        getAlerts: (state) => state.alerts
    },
    actions:
    {
        addAlert(alert:IAlert): void
        {
            if(!this.alertById.has(alert.referenceId))
            {
                this.alerts.push(alert);
                this.alertById.add(alert.referenceId);
            }
        },
        removeAlert(alert:IAlert): void
        {
            this.alerts = this.alerts.filter(a => a.referenceId !== alert.referenceId);
            this.alertById.delete(alert.referenceId);
        },
        resetAlerts(): void
        {
            this.alerts = [];
            this.alertById.clear();
        },
        async fetchAlerts(options?:{background:boolean}): Promise<IAlert[]>
        {        
            let alerts:IAlert[] = [];

            let myTeamId:string = useTeamStore().getMyTeam;
            let myTeam:ITeam = useTeamStore().getTeam(myTeamId);
            let myUserId:string = useAuthenticationStore().identityId;

            if(myUserId === myTeam?.leaderUserId)
            {
                let playerApiClient:IPlayerApiClient = options?.background ? useApiClientStore().backgroundPlayerApiClient : useApiClientStore().playerApiClient;
                let competition:ICompetition = useCompetitionStore().currentCompetition;

                let filter = new PlayerFilter({competitionId: competition.id, teamId: myTeamId, limit: Config.DEFAULT_FETCH_SIZE});
                let page:IPlayerPage;

                do
                {
                    page = await playerApiClient.get(filter);
                    filter.token = page.nextPageToken;

                    alerts = alerts.concat(page.items.filter(item => item.status === PlayerStatus.Joining)
                                                     .map(item => new JoiningRequestAlert(item)));
                }
                while(filter.token);

                alerts.forEach((alert)=>{
                    this.addAlert(alert)
                });
            }

            if(!useNotificationStore().isSubscribed(NotificationEvent.AlertUpdated))
            {
                useNotificationStore().subscribe({
                    event: NotificationEvent.AlertUpdated, 
                    callback: ()=> this.fetchAlerts({background: true})
                }); 
            }

            return alerts;
        }
    }
});
