<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('TEAMS_VIEW_DIALOG_TITLE')" :confirm="$t('TEAMS_VIEW_DIALOG_CONFIRM')" :cancel="false" @confirm="confirm" :loading="isLoading" id="teamViewDialog">
        <v-tooltip slot="titleActions" v-if="canAddSolvedSubmission" bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon outlined :disabled="isLoading" class="mr-3" @click="onAddSolvedSubmissionClicked" id="viewTeamsDialogAddSolvedSubmissionButton" :aria-label="$t('TEAMS_VIEW_DIALOG_ADD_SOLVED_SUBMISSION')">
                    <v-icon>add</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('TEAMS_VIEW_DIALOG_ADD_SOLVED_SUBMISSION') }}</span>
        </v-tooltip>
        <score-chart :teamId="team.id" :height="300" :isDark="isDark" class="teamScoreChart" />
        <team-submissions-table :teamId="team.id" :isDark="isDark" class="teamSubmissionTable" />
        <submission-correction-dialog v-model="showSubmissionCorrectionDialog" :team="team" @confirm="load"/>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ITeam } from '@cyber-range/cyber-range-api-ctf-team-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { useTeamStore } from '@/stores/teamStore';
import { useSubmissionStore } from '@/stores/submissionStore';

@Component({components:{}})
export default class ViewTeamDialog extends Vue 
{
    @Prop(Boolean) value: boolean;
    @Prop(Object) team:ITeam;


    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO

    showConfirmationDialog:boolean = false;
    showSubmissionCorrectionDialog:boolean = false;

    get canAddSolvedSubmission(): boolean
    {
        return useAuthorizationStore().canAddSolvedSubmission(this.competition.id, this.competition.organizationId);
    }

    onAddSolvedSubmissionClicked()
    {
        this.showSubmissionCorrectionDialog = true;
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        if(value) await this.load()
    }

    async mounted()
    {
        if(this.value) await this.load()
    }

    async load()
    {
        await Promise.all([useTeamStore().fetchTeams(), useSubmissionStore().fetchSubmissions()]);

        this.showConfirmationDialog = this.value
    }

    async confirm()
    {
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>

<style scoped>
#viewTeamsDialogAddSolvedSubmissionButton {
    color: inherit;
}
</style>