import moment from "moment";
import { ISubmissionApiClient, SubmissionFilter, ISubmission, ISubmissionPage, SubmissionStatus, SubmissionType, ITeamSubmissionStatistics, IChallengeSubmissionStatistics, ChallengeSubmissionStatistics } from '@cyber-range/cyber-range-api-ctf-submission-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import NotificationEvent from '@/interfaces/NotificationEvent';
import Config from '@/config';
import IExportedSubmission from '../interfaces/iExportedSubmission';
import ExportedSubmission from '../entities/exportedSubmission';
import { IPlayer } from "@cyber-range/cyber-range-api-ctf-player-client";
import { ITeam } from "@cyber-range/cyber-range-api-ctf-team-client";
import Vue from "vue";
import { useActivityStore } from "./activityStore";
import { useNotificationStore } from '@stores/notificationStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useScoreStore } from '@stores/scoreStore';
import { usePlayerStore } from './playerStore';
import { useTeamStore } from "./teamStore";
import { IChallenge } from "@cyber-range/cyber-range-api-ctf-challenge-client";
import Activity from "@/entities/activity";
import { useChallengeStore } from "./challengeStore";
import { defineStore } from "pinia";

export const useSubmissionStore = defineStore('submissionStore', {
    state: () =>
    ({
        submissions: [] as ISubmission[],
        submissionIds: new Set<string>(),
        isSubmissionFetching: false,
        isSolved: {} as Record<string,boolean>,
        myTeamsChallengesStatistics: {} as Record<string, IChallengeSubmissionStatistics>,
        isSubmissionFetched: false,
        latestCorrectionTimestamp: '',
        allowActivityCreation: false,
    }),
    getters: 
    {
        getSubmissions: (state) => (from?:string):ISubmission[] =>
        {
            return from ? state.submissions.filter(s => s.createdTimestamp >= from) : state.submissions;
        },
        isFetchedSubmission: (state) => (submissionId:string):boolean =>
        {
            return state.submissionIds.has(submissionId);
        },
        /**
         * ignoreChallengeId is for checking if a challenge was unlocked before the ignored challenge was solved
         */
        isLocked: (state) => (challengeId:string, ignoreChallengeId?: string, teamId?:string): boolean =>
        {
            const challenge: IChallenge = useChallengeStore().getChallenge(challengeId)
            if (!challenge)
            {
                return true;
            }
            const { unlockedBy } = challenge;
            if (!unlockedBy || unlockedBy.length === 0)
            {
                return false;
            }

            return !unlockedBy?.some((id) => id === ignoreChallengeId
                ? false
                : teamId
                    ? !!state.submissions.find(s => s.teamId === teamId && s.challengeId === id && s.status === SubmissionStatus.Correct)
                    : state.isSolved[id]
            );
        },
        unlocks(): (challengeId:string, teamId?: string) => string[]
        {
            return (challengeId:string, teamId?: string): string[] =>
            {
                const challengeStore = useChallengeStore();
                const challenge = challengeStore.getChallenge(challengeId);
                const challenges: string[] = []
                for (const id of challenge?.unlocks || [])
                {
                    const challengeBeingUnlocked = challengeStore.getChallenge(id);
                    if (!challengeBeingUnlocked?.name) continue;
        
                    const isAlreadyUnlocked = !this.isLocked(id, challengeId, teamId);
                    if(!isAlreadyUnlocked)
                    {
                        challenges.push(challengeBeingUnlocked.name);
                    }
                }
            return challenges;
            }
        },
        getTeamSubmissions:(state) => (teamId:string):ISubmission[] =>
        {
            return state.submissions.filter(submission=>submission.teamId === teamId);
        },
        getLatestSubmissionTimestamp: (state): string =>
        {
            return state.submissions.length > 0
                ? state.submissions.reduce((timestamp, submission) => timestamp && moment(timestamp).isAfter(submission.modifiedTimestamp) ? timestamp : submission.modifiedTimestamp, '')
                : undefined;
        },
        getLatestCorrectionTimestamp: (state): string =>
        {
            return state.latestCorrectionTimestamp;
        }
    },
    actions: 
    {
        addSubmission(submission:ISubmission): void
        {
            if(!this.submissionIds.has(submission.id))
            {
                this.submissions.push(submission);
                this.submissionIds.add(submission.id);
            }
        },
        clearSubmissions(): void
        {
            this.submissions = [];
            this.submissionIds.clear();
            this.isSubmissionFetched = false;
        },
        beginSubmissionFetching(): void
        {
            this.isSubmissionFetching = true;
        },
        endSubmissionFetching(): void
        {
            this.isSubmissionFetching = false;
            this.isSubmissionFetched = true;
        },
        solved(submission:ISubmission): void
        {
            Vue.set(this.isSolved, submission.challengeId, true);
        },
        resetSolved(challengeId:string): void
        {
            Vue.set(this, 'isSolved', {});
        },
        setLatestCorrectionTimestamp(timestamp): void
        {
            this.latestCorrectionTimestamp = timestamp;
        },
        setMyTeamsChallengesStatistics(challengesStatistics: IChallengeSubmissionStatistics[]): void
        {
            for (const challengeStatistics of challengesStatistics)
            {
                Vue.set(this.myTeamsChallengesStatistics, challengeStatistics.challengeId, challengeStatistics);
            }
        },
        startCapturingActivities()
        {
            this.allowActivityCreation = true;
            const activityStore = useActivityStore()
            activityStore.resetActivities();
            for (const submission of this.submissions)
            {
                activityStore.addActivity(...Activity.fromSubmission(submission))
            }
        },
        async fetchSubmissions(options?:{background:boolean}): Promise<void> 
        {
            if(this.isSubmissionFetching) return;

            this.beginSubmissionFetching();

            options = options || {background:false};

            try
            {
                const latestCorrectionTimestamp = this.getLatestCorrectionTimestamp;
                const latestSubmissionTimestamp = this.getLatestSubmissionTimestamp;

                let competition:ICompetition = useCompetitionStore().currentCompetition;
                
                let myTeamId:string = useTeamStore().getMyTeam;

                let client:ISubmissionApiClient = options.background ? useApiClientStore().backgroundSubmissionApiClient : useApiClientStore().submissionApiClient;

                let filter = new SubmissionFilter({competitionId: competition.id, from: latestSubmissionTimestamp, scoreChange: true, limit: Config.SUBMISSION_FETCH_SIZE});

                let page:ISubmissionPage;

                do
                {
                    page = await client.get(filter);
                    filter.token = page.nextPageToken;
                    
                    for(let submission of page.items)
                    {
                        // If new unsolve submission (correction w/ scoreAdjustment of 0), then clear store and refetch all submissions
                        if (submission.type === SubmissionType.Correction && submission.scoreAdjustment === 0 && (!latestCorrectionTimestamp || moment(submission.modifiedTimestamp).isAfter(latestCorrectionTimestamp)))
                        {
                            this.endSubmissionFetching()

                            const latestCorrectionTimestamp = page.items.reduce((timestamp,submission) => submission.type === SubmissionType.Correction && moment(submission.modifiedTimestamp).isAfter(timestamp) ? submission.modifiedTimestamp : timestamp, submission.modifiedTimestamp);
                            this.setLatestCorrectionTimestamp(latestCorrectionTimestamp);
                            this.clearSubmissions();
                            useScoreStore().resetScoreHistories();
                            useActivityStore().resetActivities();
                            this.resetSolved();

                            await this.fetchSubmissions();
                            return
                        }
                        if(!this.isFetchedSubmission(submission.id))
                        {
                            this.addSubmission(submission);
                            useScoreStore().addTeamScoreHistory(submission);
                            if (this.allowActivityCreation)
                            {
                                useActivityStore().addActivity(...Activity.fromSubmission(submission));
                            }
                            
                            if(submission.teamId === myTeamId && submission.status === SubmissionStatus.Correct)
                            {
                                this.solved(submission);
                            }
                        }
                    }
                }
                while(filter.token);
            }
            finally
            {
                this.endSubmissionFetching()
            }

            if(!useNotificationStore().isSubscribed(NotificationEvent.SubmissionUpdated))
            {
                useNotificationStore().subscribe({
                    event: NotificationEvent.SubmissionUpdated, 
                    callback: ()=> this.fetchSubmissions({background: true})
                }); 
            };
        },
        async fetchMyTeamsChallengesStatistics(options:{background?:boolean}): Promise<void> 
        {
            const competitionId: string = useCompetitionStore().currentCompetition.id;
            const myTeamId: string = useTeamStore().getMyTeam
            if (!myTeamId)
            {
                return
            }

            let client:ISubmissionApiClient = options.background ? useApiClientStore().backgroundSubmissionApiClient : useApiClientStore().submissionApiClient;

            const challengesSubmissionData: IChallengeSubmissionStatistics[] = []
            const filter = new SubmissionFilter();

            do
            {
                const response = await client.listCompetitionTeamChallengesStatistics(competitionId, myTeamId, filter);
                challengesSubmissionData.push(...response.items);
                filter.token = response.nextPageToken;
            } while (filter.token);

            this.setMyTeamsChallengesStatistics(challengesSubmissionData);

            if(!useNotificationStore().isSubscribed(NotificationEvent.ChallengeStatisticsUpdated))
            {
                useNotificationStore().subscribe({
                    event: NotificationEvent.ChallengeStatisticsUpdated, 
                    callback: ()=>this.fetchMyTeamsChallengesStatistics({background: true})
                });
            }
        },
        async getTeamChallengeSubmissionStatistics(options:{teamId:string, challengeId:string, background?:boolean}): Promise<ITeamSubmissionStatistics> 
        {
            let competition = useCompetitionStore().currentCompetition;
            let client:ISubmissionApiClient = options.background ? useApiClientStore().backgroundSubmissionApiClient : useApiClientStore().submissionApiClient;

            const statistics = await client.getCompetitionTeamChallengeStatistics(competition.id, options.teamId, options.challengeId)
            this.setMyTeamsChallengesStatistics([new ChallengeSubmissionStatistics({ challengeId: options.challengeId, ...statistics})])
            return statistics
        },
        async exportSubmissions(): Promise<IExportedSubmission[]>
        {
            let exportedSubmissions:IExportedSubmission[] = [];
            
            //Fetch players
            let players:IPlayer[] = await usePlayerStore().fetchPlayers();
            let playerTable = {};
            players.forEach(player => playerTable[player.id] = player);

            //Export each submission
            const competition:ICompetition = useCompetitionStore().currentCompetition;
            const submissionsFilter = new SubmissionFilter({ competitionId: competition.id, includeSubmittedFlag: true });
            const submissions:ISubmission[] = [];
            const client:ISubmissionApiClient = useApiClientStore().submissionApiClient;
            do
            {
                const page = await client.get(submissionsFilter);
                submissionsFilter.token = page.nextPageToken
                submissions.push(...page.items);
            }
            while (submissionsFilter.token);

            for(let submission of submissions)
            {
                    let team:ITeam = useTeamStore().getTeam(submission.teamId);
                    
                    if(!team || team.hidden)
                    {
                        //Ignore submissions from deleted and hidden teams.
                        continue;
                    }
                    
                    let playerName = playerTable[submission.playerId]?.name || '';

                    exportedSubmissions.push(new ExportedSubmission({
                                                      playerName, 
                                                      teamName: team.name, 
                                                      challengeName: submission.challengeName, 
                                                      challengeCategory: submission.challengeCategory, 
                                                      scoreAdjustment: submission.scoreAdjustment, 
                                                      createdTimestamp: submission.createdTimestamp,
                                                      flag: submission.submittedFlag,
                                                    }));
            }

            return exportedSubmissions;
        }
    }
});
