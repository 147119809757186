<template>
    <div>
        <v-row row wrap class="ma-2 py-3" justify="space-between">
            <h2 class="px-3">{{$t('PLAYERS_TITLE')}}</h2>
            <v-tooltip v-if="invitationEnabled" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn @click="onAddClicked" v-on="on" small fab outlined :aria-label="$t('PLAYERS_ADD_TOOLTIP')" data-testing="players-add-player-button">
                        <v-icon>add</v-icon>
                    </v-btn>
                </template>
                {{$t('PLAYERS_ADD_TOOLTIP')}}
            </v-tooltip>
        </v-row>
        <v-data-table show-select v-model="selectedPlayers" :dark="isDark" :headers="headers" :items="players" item-key="tableKey" :loading="isLoading" :loading-text="$t('PLAYERS_LOADING')" :no-data-text="$t('PLAYERS_NO_DATA')" class="elevation-0 alphaBackground" id="managePlayersTable" @toggle-select-all="onToggleSelectAllPlayers" @item-selected="onPlayerSelected" @current-items="onTableItemsChange">

            <template v-slot:top>
                <table-header deleteButton :selected="selectedPlayers.length" :page="pageSize" :total="playersCount" item="players" @select-all="onSelectAllPlayers" @clear="onClearSelectedPlayers" @delete="onDeleteSelectedPlayers"/>
            </template>

            <template v-slot:item.action="{ item }">
                
                <accessible-menu :id="`${item.tableKey}-player-menu`" :disabled="!viewHasMenuItems(item, competition)" offset-y :label="$t('PLAYERS_ACTIONS_LABEL', { name: item.name })">
                    <v-list>
                        <v-list-item v-if="playerHistoryIsViewable(item)" @click="onViewHistoryClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>history</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('PLAYERS_VIEW_HISTORY')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="playerIsEditable(item, competition)" @click="onEditClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>edit</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('PLAYERS_EDIT')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="invitationIsEditable(item, competition)" @click="onAddEditInvitationClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>edit</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('PLAYERS_EDIT')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="playerIsDemotable(item, competition)" @click="onDemoteClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>arrow_downward</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('PLAYERS_DEMOTE')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="invitationIsResendable(item, competition)" @click="onReinviteClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>mail_outline</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('PLAYERS_REINVITE')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="invitationIsDemotable(item, competition)" @click="onDemoteClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>arrow_downward</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('PLAYERS_DEMOTE')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="playerIsPromotable(item, competition)" @click="onPromoteClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>arrow_upward</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('PLAYERS_PROMOTE')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="invitationIsPromotable(item, competition)" @click="onPromoteClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>arrow_upward</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('PLAYERS_PROMOTE')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="playerIsDeleteable(item, competition)" @click="onDeleteClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>delete</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('PLAYERS_DELETE')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="invitationIsDeletable(item, competition)" @click="onDeleteClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>delete</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('PLAYERS_DELETE')}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </accessible-menu>

            </template>
            <template v-slot:item.name="{ item }">
                {{ item.name }}
                <v-tooltip v-if="item.invitation" bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small class="ml-1">
                            mail_outline
                        </v-icon>
                    </template>
                    <span>{{$t('PLAYERS_INVITATION')}}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.isAdmin="{ item }">
                <template v-if="item.isAdmin">
                    <v-icon>checked</v-icon>
                    <span class="visually-hidden">{{$t('PLAYERS_IS_ADMIN_TEXT')}}</span>
                </template>
            </template>
            <template v-slot:item.stars="{ item }">
                <v-rating :show="true" :value="item.stars" half-increments :readonly="true"/>
            </template>
            <template v-slot:header.action>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" :aria-label="$t('PLAYERS_EXPORT_LABEL')" 
                            @click="onExportClicked">save_alt</v-icon>
                    </template>
                    <span>{{$t('PLAYERS_EXPORT_LABEL')}}</span>
                </v-tooltip>
            </template>
            <template v-slot:header.data-table-select="{ props, on }">
                <v-checkbox :aria-label="$t('PLAYERS_TOGGLE_SELECT_ALL_LABEL')" class="pa-0 ma-0"
                    hide-details @change="on.input" :input-value="props.value" 
                    :indeterminate="props.indeterminate" />
            </template>
            <template v-slot:item.data-table-select="{ item, isSelected, select }">
                <v-checkbox class="pa-0 ma-0" :disabled="isCurrentPlayer(item)" hide-details
                    :aria-label="$t('PLAYERS_SELECT_LABEL', {name: item.name})" 
                    @change="select" :input-value="isSelected" />
            </template>
        </v-data-table>
        <player-history-dialog v-model="showPlayerHistoryDialog" :player="selectedPlayer.player" />
        <edit-player-dialog v-model="showPlayerEditDialog" :playerId="selectedPlayerId" :allowTeamChange="canKickPlayerFromTeam(selectedPlayer.teamId, competition.id, competition.organizationId) && canApproveTeamMembershipRequest(selectedPlayer.teamId, competition.id, competition.organizationId)" @confirm="refresh" />
        <delete-player-dialog v-model="showPlayerDeleteDialog" :player="selectedPlayer" @confirm="refresh" />
        <delete-players-dialog v-model="showPlayersDeleteDialog" :players="selectedPlayers" @confirm="refresh"/>
        <promote-player-dialog v-model="showPromotePlayerDialog" :player="selectedPlayer" @confirm="refresh" />
        <demote-player-dialog v-model="showDemotePlayerDialog" :player="selectedPlayer" @confirm="refresh" />
        <reinvite-player-dialog v-model="showReinvitePlayerDialog" :player="selectedPlayer" @confirm="onInvitationSent" />
        <invitation-sent-dialog v-model="showInvitationSentDialog" />
        <add-edit-invitation-dialog v-model="showAddEditInvitationDialog" :invitation="selectedPlayer.invitation" @confirm="refresh"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IPlayerApiClient, PlayerFilter, IPlayerPage, IPlayer, Player } from '@cyber-range/cyber-range-api-ctf-player-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import PlayerView from './PlayerView';
import {csvExport} from '@/utils/csvExport';
import { IUser, UserFilter, UserRole, IUserApiClient, UserStatus } from '@cyber-range/cyber-range-api-user-client';
import { IInvitationApiClient, IInvitation, InvitationFilter } from '@cyber-range/cyber-range-api-invitation-client';
import Config from '@/config';
import TitleStrings from '@/entities/strings/definitions/titleStrings';
import { IPageResponse } from '@cyber-range/cyber-range-api';
import { useThemeStore } from '@/stores/themeStore';
import { useFeatureStore } from '@/stores/featureStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { useTeamStore } from '@/stores/teamStore';
import { usePlayerStore } from '@/stores/playerStore';

@Component({ metaInfo: { title: TitleStrings.en.TITLE_MANAGE_PLAYERS }})
export default class Manage extends Vue 
{
    get currentPlayer(): IPlayer
    {
        return usePlayerStore().getCurrentPlayer;
    }

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get invitationEnabled(): boolean
    {
        return useFeatureStore().invitationEnabled;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get userApiClient(): IUserApiClient
    {
        return useApiClientStore().userApiClient;
    }
    get invitationApiClient(): IInvitationApiClient
    {
        return useApiClientStore().invitationApiClient;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().playerApiClient;
    }
    get currentUserId(): string
    {
        return useAuthenticationStore().identityId;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canApproveTeamMembershipRequest(teamId:string, competitionId: string, organizationId: string): boolean
    {
        return useAuthorizationStore().canApproveTeamMembershipRequest(teamId, competitionId, organizationId);
    }
    canKickPlayerFromTeam(teamId:string, competitionId: string, organizationId: string): boolean
    {
        return useAuthorizationStore().canKickPlayerFromTeam(teamId, competitionId, organizationId);
    }
    // END TODO

    players:PlayerView[] = [];
    headers = [];

    visiblePlayers:PlayerView[] = [];
    selectedPlayer:PlayerView = <PlayerView>{player:{}};
    selectedPlayers:PlayerView[] = [];
    showPlayerDeleteDialog = false;
    showPlayersDeleteDialog = false;
    showPlayerEditDialog = false;
    showPlayerHistoryDialog = false;
    showPromotePlayerDialog = false;
    showDemotePlayerDialog = false;
    showReinvitePlayerDialog = false;
    showInvitationSentDialog = false;
    showAddEditInvitationDialog = false;

    get selectedPlayerId():string
    {
        return this.selectedPlayer?.player?.id;
    }

    get pageSize(): number
    {
        // Don't count current player in page count - used for select all button
        return this.visiblePlayers.filter(playerView => !this.isCurrentPlayer(playerView)).length;
    }

    get playersCount(): number
    {
        // Don't count current player in player count - used for select all button
        return this.players.filter(playerView => !this.isCurrentPlayer(playerView)).length;
    }
    
    viewHasMenuItems(item: PlayerView, competition: ICompetition) {
        return item.isPlayer ? 
            this.playerHistoryIsViewable(item) ||
            this.playerIsEditable(item, competition) ||
            this.playerIsDeleteable(item, competition) ||
            this.playerIsDemotable(item, competition) ||
            this.playerIsPromotable(item, competition) :
            this.invitationIsEditable(item, competition) ||
            this.invitationIsDemotable(item, competition) ||
            this.invitationIsPromotable(item, competition) ||
            this.invitationIsResendable(item, competition) ||
            this.invitationIsDeletable(item, competition);
    }

    playerHistoryIsViewable(item: PlayerView) {
        return item.hasPlayerRecord && useAuthorizationStore().canViewAllCompetitions(this.competition.organizationId);
    }

    playerIsEditable(item: PlayerView, competition: ICompetition): boolean {
        return item.isPlayer && item.hasPlayerRecord && 
        useAuthorizationStore().canUpdatePlayer(competition.id, competition.organizationId)
    }

    playerIsDeleteable(item: PlayerView, competition: ICompetition): boolean {
        return !this.isCurrentPlayer(item) && item.isPlayer &&
            useAuthorizationStore().canDeletePlayer(competition.id, competition.organizationId)
    }

    playerIsDemotable(item: PlayerView, competition: ICompetition): boolean {
        return !this.isCurrentPlayer(item) && item.isAdmin && item.isPlayer && 
            useAuthorizationStore().canUpdateCompetitionUserRole(competition.id, competition.organizationId);
    }
    
    playerIsPromotable(item: PlayerView, competition: ICompetition): boolean {
        return !this.isCurrentPlayer(item) && !item.isAdmin && item.isPlayer && 
            useAuthorizationStore().canUpdateCompetitionUserRole(competition.id, competition.organizationId)
    }
    
    invitationIsEditable(item: PlayerView, competition: ICompetition): boolean {
        return item.isInvitation && useAuthorizationStore().canUpdateInvitation(competition.id, competition.organizationId)
    }
    
    invitationIsDemotable(item: PlayerView, competition: ICompetition): boolean {
        return item.isAdmin && item.isInvitation && 
            useAuthorizationStore().canUpdateInvitation(competition.id, competition.organizationId) && 
            useAuthorizationStore().canInviteCtfPlayer(competition.id, competition.organizationId)
    }
    
    invitationIsPromotable(item: PlayerView, competition: ICompetition): boolean {
        return !item.isAdmin && item.isInvitation && 
            useAuthorizationStore().canUpdateInvitation(competition.id, competition.organizationId) && 
            useAuthorizationStore().canInviteCtfPlayer(competition.id, competition.organizationId)
    }
    
    invitationIsResendable(item: PlayerView, competition: ICompetition): boolean {
        return item.isInvitation && 
            useAuthorizationStore().canResendInvitation(competition.id, competition.organizationId);
    }
    
    invitationIsDeletable(item: PlayerView, competition: ICompetition): boolean {
        return item.isInvitation && 
            useAuthorizationStore().canDeleteInvitation(competition.id, competition.organizationId)
    }

    created()
    {
        this.headers = [
            { text: '', value: 'action', align: 'center', sortable: false },
            { text: this.$t('PLAYERS_HEADER_USER'), value: 'name' },
            { text: this.$t('PLAYERS_HEADER_EMAIL'), value: 'email' },
            { text: this.$t('PLAYERS_HEADER_AFFILIATION'), value: 'affiliation' },
            { text: this.$t('PLAYERS_HEADER_TEAM'), value: 'teamName' },
            { text: this.$t('PLAYERS_HEADER_ADMIN'), value: 'isAdmin' },
            { text: this.$t('PLAYERS_HEADER_CORRECT_FLAG_SUBMISSION'), value: 'stars', align: 'center' },
            { text: this.$t('PLAYERS_HEADER_SCORE'), value: 'score', align: 'right' }
        ]
    }
    async mounted() 
    {
        await this.refresh();
    }

    async refresh()
    {
        this.onClearSelectedPlayers();
        let [ctfAdmins, ctfPlayers, invitations] = await Promise.all([
                                                        this.fetchCtfAdmins(), 
                                                        this.fetchPlayers(),
                                                        this.fetchInvitations(),
                                                        useTeamStore().fetchTeams()]);

        const views = new Map<string,PlayerView>();
        let ctfAdminTable = new Set(ctfAdmins.map(u=>u.id));

        for(let player of ctfPlayers)
        {
            let view = new PlayerView(player);
            view.isAdmin = false;
            view.teamName = useTeamStore().getTeam(player.teamId)?.name || '';
            views.set(player.userId, view);
        }

        for (let admin of (ctfAdmins as IUser[]))
        {
            const view = views.get(admin.id) || new PlayerView(new Player({
                userId: admin.id,
                name: admin.name,
                competitionId: this.competition.id,
                email: admin.email
            }));
            view.isAdmin = true;
            view.tableKey = view.hasPlayerRecord ? view.player.id : admin.id;
            views.set(admin.id, view);
        }

        for(let invitation of invitations)
        {
            let view = PlayerView.fromInvitation(invitation);
            views.set(invitation.id, view);
        }

        this.players = Array.from(views.values());
    }

    async fetchPlayers(): Promise<IPlayer[]>
    {
        let newPlayers = [];

        let filter = new PlayerFilter({competitionId: this.competition.id, limit:Config.DEFAULT_FETCH_SIZE});
        let page:IPlayerPage;
        
        do
        {
            page = await this.playerApiClient.get(filter);
            filter.token = page.nextPageToken;
            newPlayers = newPlayers.concat(page.items);
        }
        while(page.nextPageToken);

        return newPlayers;
    }

    async fetchCtfAdmins(): Promise<IUser[]>
    {
        let filter = new UserFilter({competitionId: this.competition.id, roles: [UserRole.CtfAdmin], status: UserStatus.Ready });
        return await this.userApiClient.listAllUsers(filter);
    }

    async fetchInvitations(): Promise<IInvitation[]>
    {
        if(!this.invitationEnabled) return [];

        let invitations = [];

        let filter = new InvitationFilter();
        filter.competitionId = this.competition.id;
        let request: IPageResponse<IInvitation>;

        do
        {
            request = await this.invitationApiClient.get(filter);
            filter.token = request.nextPageToken;
            invitations = invitations.concat(request.items);
        }
        while(request.nextPageToken);

        return invitations;
    }

    isCurrentPlayer(playerView: PlayerView): boolean
    {
        return (this.currentPlayer?.id || this.currentUserId) === playerView.tableKey;
    }

    onViewHistoryClicked(player:PlayerView)
    {
        this.selectedPlayer = player;
        this.showPlayerHistoryDialog = true;
    }

    onDemoteClicked(player:PlayerView)
    {
        this.selectedPlayer = player;
        this.showDemotePlayerDialog = true;
    }

    onPromoteClicked(player:PlayerView)
    {
        this.selectedPlayer = player;
        this.showPromotePlayerDialog = true;
    }

    onEditClicked(player:PlayerView)
    {
        this.selectedPlayer = player;
        this.showPlayerEditDialog = true;
    }

    onAddClicked()
    {
        this.selectedPlayer = new PlayerView(undefined);
        this.showAddEditInvitationDialog = true;
    }

    onAddEditInvitationClicked(player:PlayerView)
    {
        this.selectedPlayer = player;
        this.showAddEditInvitationDialog = true;
    }

    onReinviteClicked(player:PlayerView)
    {
        this.selectedPlayer = player;
        this.showReinvitePlayerDialog = true;
    }

    onInvitationSent()
    {
        this.showInvitationSentDialog = true;
    }

    onDeleteClicked(player:PlayerView)
    {
        this.selectedPlayer = player;
        this.showPlayerDeleteDialog = true;
    }

    async onExportClicked()
    {
        await csvExport(
                this.headers.slice(1).map(h=>h.text), 
                this.players.map(p => [p.name, p.email, p.affiliation, p.teamName, p.isAdmin, `${p.stars*20}%`, p.score]), 
                `ctf_players.csv`);
    }

    onTableItemsChange(items: PlayerView[])
    {
        this.visiblePlayers = [...items];
    }

    onToggleSelectAllPlayers({ value }: { value: boolean, items: PlayerView[] })
    {
        // Clear all player if value if false
        if (!value || this.selectedPlayers.length >= this.pageSize)
        {
            this.onClearSelectedPlayers();
        }
        else {
            this.selectedPlayers = this.visiblePlayers.filter(player => !this.isCurrentPlayer(player));
        }
    }

    onPlayerSelected({ value, item }: { value: boolean, item: PlayerView })
    {
        // If a player on the page is deselected when all players were selected, change selection to only be the players on this page minus the deselected player
        if (this.selectedPlayers.length === this.playersCount && !value)
        {
            this.selectedPlayers = this.visiblePlayers.filter(player => 
                !this.isCurrentPlayer(player) && player.tableKey !== item.tableKey);
        }
    }

    onSelectAllPlayers()
    {
        this.selectedPlayers = [...this.players]
            .filter(playerView => !this.isCurrentPlayer(playerView));
    }

    onClearSelectedPlayers()
    {
        this.selectedPlayers = [];
    }

    onDeleteSelectedPlayers()
    {
        if (this.selectedPlayers.length === 1)
        {
            this.selectedPlayer = this.selectedPlayers[0];
            this.showPlayerDeleteDialog = true;
        }
        else if (this.selectedPlayers.length > 1)
        {
            this.showPlayersDeleteDialog = true;
        }
        else {
            // Do nothing
        }
    }
}
</script>

<style scoped>
.theme--dark .v-data-table
{
    
}
</style>
<style>
.v-data-table td
{
    color: var(--v-text-darken1) !important;
}
</style>
