<template>
    <v-row wrap>
        <v-col cols="12" class="pa-0 mb-0 mt-1" v-for="(flag,index) in flags" :key="index" :class="'flag'">
            <v-row wrap class="pa-0 ma-0">
                <v-col cols="12" sm="5" md="3" class="pt-0 pb-0 mb-0 mt-0" >
                    <sorted-select v-model="flag.type" :items="flagTypes" :label="$t('FLAGS_TYPE')" :rules="flagTypeRules" :disabled="isLoading" class="flagType" />
                </v-col>
                <v-col cols="12" sm="7" md="9" class="pt-0 pb-0 mb-0 mt-0" >
                    <v-text-field v-model="flag.value" :label="$t('FLAGS_VALUE')" :placeholder="flagValuePlaceholder(flag.type)" append-outer-icon="delete" @click:append-outer="onDeleteClicked(index)" @update:error="onError" :rules="flagValueRules(flag.type)" :counter="maxFlagValueLength" :disabled="isLoading" class="flagValue" />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0 mb-0 mt-1 addFlag">
            <a @click.prevent="onAddClicked()">
                <v-icon class="mr-2">add</v-icon> 
                {{$t('FLAGS_ADD_FLAG')}}
            </a>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IChallengeArtifact, ChallengeArtifactType, Flag, IFlag, FlagType } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class TextFlags extends Vue
{  
    @Prop(Array) flags:Flag[];

    maxFlagValueLength:number = 1024;

    flagTypeRules = [Rule.selectRequire];
    flagValueRules(type: FlagType): any[]
    {
        const rules: any[] = [Rule.require, (v)=>Rule.maxLength(v, this.maxFlagValueLength)];
        if (type === FlagType.Regex)
        {
            rules.push(Rule.isValidRegexp);
        }
        return rules;
    }

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO
    
    
    get flagTypes(): {text:string, value:string}[]
    {
        return [
            { text: 'String', value: FlagType.String },
            { text: 'Regex', value: FlagType.Regex },
        ];
    }

    flagValuePlaceholder(type:FlagType): string
    {
        return type === FlagType.Regex ? '/example/ig' : '';
    }

    onAddClicked()
    {
        this.flags.push(new Flag({type: FlagType.String}));
    }

    onDeleteClicked(index:number)
    {
        this.flags.splice(index, 1);
    }

    onError(error:any)
    {
        this.$emit('update:error', error)
    }
}
</script>