<template>
    <div v-if="competition">
        <v-btn class='pt-4 pb-4 pl-5 pr-5' depressed color="confirm" id="landingButtonLogin" :disabled="isLoading" :loading="isLoading" :dark="!isConfirmLight" :light="isConfirmLight" @click="onLoginClicked">
            <template v-if="active">
                {{$t('LANDING_LOGIN_ACTIVE_COMPETITION')}}
            </template>
            <template v-else>
                {{$t('LANDING_LOGIN_EXPIRED_COMPETITION')}}
            </template>
        </v-btn>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import moment from 'moment';
import Route from '@interfaces/route';
import { useThemeStore } from "@/stores/themeStore";
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class LandingButtons extends Vue 
{
    @Prop(Object) competition:ICompetition;

    // TODO: Change this to composition api
    get isConfirmLight(): boolean
    {
        return useThemeStore().isConfirmLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO

    get active()
    {
        return (!this.competition?.settings?.endTime || moment().isBefore(this.competition?.settings?.endTime));
    }

    onLoginClicked()
    {
        this.$router.push(Route.Challenges);
    }
}
</script>

<style scoped>
.v-btn.v-size--default
{
    height: auto;
}
</style>